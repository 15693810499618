.rod__label {
	color: @font1;
	font-size: 12px;
	padding-right: 5px;
	padding-left: 3px;
	padding-top: 2px;
	padding-bottom: 2px;
	height: 22px;
}

.rod__subtitle {
	color: @font1;
	font-size: 12px;
	font-weight: bold;
	padding-left: 5px;
}

.rod__container {
	display: -ms-grid;
	display: grid;
	gap: 1.5rem;
	-ms-grid-columns: 1fr 1.5rem 1fr 1.5rem 1fr 1.5rem 1fr;
	grid-template-columns: repeat(4, 1fr);
}

.rod__container a {
	text-decoration: none;
}

.rod__title {
	grid-column: ~"1 / -1";
	margin: 0;
	padding: 0;
	line-height: 1;
}

.rod__card {
	border: 1px solid @primary3;
	border-radius: 8px;
	background: @primary4;
	-webkit-transition: -webkit-transform 0.1s;
	transition: -webkit-transform 0.1s;
	-o-transition: transform 0.1s;
	transition: transform 0.1s;
	transition: transform 0.1s, -webkit-transform 0.1s;
	cursor: pointer;
}

.rod__card:hover {
	-webkit-box-shadow: 0px 2px 4px -2px rgba(13, 13, 13, 0.12),
		0px 4px 4px -2px rgba(13, 13, 13, 0.08);
	box-shadow: 0px 2px 4px -2px rgba(13, 13, 13, 0.12),
		0px 4px 4px -2px rgba(13, 13, 13, 0.08);
	-webkit-transform: translateY(-3px);
	-ms-transform: translateY(-3px);
	transform: translateY(-3px);
}

.rod__card h3 {
	font-size: 3.5rem;
	line-height: 7rem;
	margin: 0px;
	text-align: center;
	color: @secondary1;
}

.rod__card p {
	line-height: 1;
	margin: 0px;
	margin-bottom: 15px;
	text-align: center;
	text-decoration: none;
	color: @secondary1;
}

// Styling voor BRONBewerkTerugkoppelingPage

.main_title {
	color: @font1;
	font-size: @font-size;
	padding-left: 2px;
	padding-bottom: 5px;
	font-weight: bold;
}

.main_subtitle {
	color: @font1;
	font-weight: bold;
	padding-left: 5px;
}

.main_table {
	width: 100%;
	color: @black;
	border: 0;
	empty-cells: show;
}

.main_table .main_table {
	border: @dungrijs;
	box-shadow: 1px 1px 1px @gray-30;
	background-color: @white;
}