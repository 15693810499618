// ============ Feedback

ul.feedbackPanel {
	padding-left: 0;
	> li.feedbackPanelWARNING,
	li.feedbackPanelERROR {
		padding: 8px;
		margin: 4px 0;
		border: 2px solid @color-brand;
		border-radius: 2px;
		list-style: none;
		> span {
			color: @color-brand;
			font-weight: bold;
		}
	}
	> li.feedbackPanelINFO {
		padding: 8px;
		margin: 4px 0;
		border: 2px solid @tertiary31;
		border-radius: 2px;
		list-style: none;
		> span {
			color: @tertiary31;
			font-weight: bold;
		}
	}
}

.authenticator {
	li {
		width: 100%;
		box-sizing: border-box;
		border-radius: 4px;
	}

	li.feedbackPanelERROR {
		background-color: @color-authenticator-error-background;
	}
}
