/**
 * scrollableTbl is used to make scrollable table with fixed columns
 * fixedColumn is used to make necessary columns sticky
 **/

.scrollableTbl {
	position: relative;
	overflow: visible;
	z-index: 1;
}

.scrollableTbl th,
.scrollableTbl td {
	border-top: 1px solid @greyscale5;
}

.scrollableTbl table thead tr th div span,
.scrollableTbl table thead tr th {
	white-space: initial;
}

.scrollableTbl .fixedColumn {
	position: -webkit-sticky;
	position: sticky;
	background-clip: padding-box;
	/* FIXME: deze left (en die bij .fixedColumn0) -27px is gebaseerd op de layout van GroepNietMethodetoets(Edit/Vergelijken)Page.html
	dit is niet out of the box herbruikbaar op jouw nieuwe custom table */
	left: -27px;
	min-width: 180px;
	background-color: @greyscale7 !important;
	z-index: 1;
}

.scrollableTbl .fixedColumnIconLeft {
	left: -27px;
	min-width: 20px;
}
.scrollableTbl .fixedColumnColspan3 {
	left: -46px;
	
	span.padding_left_35 {
		padding-left: 35px;
	}
}
.scrollableTbl .fixedColumnAchternaam {
	left: 0;
	min-width: 150px;
}
.scrollableTbl .fixedColumnRoepnaam {
	left: 150px;
	min-width: 120px;
}
.scrollableTbl .fixedColumnIconRight {
	left: 260px;
	min-width: 20px;
}

.scrollableTbl .fixedColumn-26px {
	left: -26px !important;
}
.scrollableTbl .fixedColumn-35px {
	left: -35px !important;
	.noBoxShadow;
}
.scrollableTbl .fixedColumn-34px {
	left: -34px !important;
}
.scrollableTbl .fixedColumn-46px {
	left: -46px !important;
}

.scrollableTbl th.dottedcolseparator,
.scrollableTbl td.dottedcolseparator {
	border-left: 1px solid @greyscale5;
}

.fixedColumnHeader,
.fixedColumnFooter {
	position: -webkit-sticky;
	position: sticky;
	left: -27px;
	background-color: @greyscale7;
	z-index: 3; // sticky blockjes van header en footer moeten hoger dan semi-sticky header en/of footer en sticky eerste kolommen
}
