.card {
	display: grid;
	position: relative;
  	grid-template-columns: 1fr 2fr;
  	border: 1px solid @greyscale6;
	border-radius: 4px;
	background: @white; 
	margin-bottom: 8px;
	font-size: @font-size;
	padding: 12px;
	
	h3 {
		margin: 8px 0;
		grid-column: span 2;
		max-width: 70%;
		.tekstafbraak;
	}
	 
	.iconClick {
		position: absolute;
		margin-top: 8px;
		width: 28px !important;
		height: 28px !important;
		align-content: center;
		
		&.icon-edit {
			right: 50px;
			top: 4px;
		}
		
		&.icon-trashcan {
			right: 12px;
			top: 4px;	
		}
	} 
	
	label[data-key="value-diagnoseGesteldDoor"] {
		max-width: 100%;
		.tekstafbraak;
	}
}

.cardlist {  
 	padding: 12px;
 	border-radius: 4px;
	border: 1px solid @greyscale6;
	background: @greyscale8;
	
	i.icon-add {
		margin-right: 4px;
	}
}

.gezinpage .cardlist{
	background: @greyscale7;
} 

.tekstafbraak {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}