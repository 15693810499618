#dossier-klaargezet {

	h1, h2 {
		margin-bottom: 5px;
	}

	.bloemmelding {
		display: flex;
		align-items: center;
		padding: 10px;
		background: @tertiary34;
		border: 1px solid @tertiary31;
		border-radius: 4px;
		color: @black;

		&.error {
			color: #e8436e;
			font-size: 14px;
			font-weight: bold;
			border: 2px solid @primary1;
			background: none;
		}

		span.icon-flower {
			color: @tertiary31;
			margin-right: 10px;
		}
	}

	.container {
		gap: 40px;
		margin-top: 24px;
		display: flex;

		.fieldsetbox {
			box-shadow: none;
			border: 1px solid @greyscale7;
		}

		.samenvatting {
			flex: 1 0 0;

			.item {
				display: flex;
				padding: 5px 0;

				.label {
					width: 50%;
				}

				a {
					display: flex;
					align-items: center;

					.icon-text-file {
						font-size: 24px;
						padding-right: 5px;
					}
				}
			}
		}

		.geschiedenis {
			flex: 1 0 0;
		}
	}
}

@media (max-width: 1023px) {
	.container {
		flex-direction: column;
	}
}
