TABLE.opp-samenvatting {
	width: 100%;
	background: @white;

	border: 1px solid @greyscale4;
	border-radius: 4px;
	border-collapse: collapse;

	THEAD {
		background: @greyscale7;
		border-bottom: 1px solid @greyscale4;
		
		TH {
			border-left: 1px solid @greyscale4;
		}
	}

	TR {
		height: 32px;
		border-bottom: 1px solid @greyscale4;

		&:nth-child(even) {
			background: @greyscale8;
		}

		TD {
			border-left: 1px solid @greyscale4;
			
			&:not(:first-child) {
				text-align: center;
				width: 150px;
			}

			&:first-child {
				padding-left: 16px;
			}
		}
		
		&.title {
			background: @greyscale6;
			font-weight: 500;
			
			TD {
				border-left: none;
			}
		}
	}
		
	.redCircle {
		background-color: @primary3;
		border: 2px solid @primary1;
		border-radius: 50%;
		width: 14px;
		height: 14px;
		float: left;
	}

	.circleSpacing {
		width: 8px;
		display: inline-block;
	}

	.purpleCircle {
		background-color: @secondary3;
		border: 2px solid @secondary1;
		border-radius: 50%;
		width: 14px;
		height: 14px;
		float: right;
	}

	.heeftTooltip:hover {
		background: @primary4;
		cursor: help;
	}
}