.infoPanel {
	display: flex;
	align-items: center;
	gap: 10px;
	vertical-align: top;
	padding: 8px;
	border-radius: 2px;
	background-color: @tertiary24;
	color: @tertiary21;

	&.icon-info-alt:before {
		font-size: 18px;
	}

	label.htmlLabel {
		p {
			a:active, a:visited, a:link, a:hover {
				color: @tertiary21;
				text-decoration: underline;
			}
		}
	}

	&.highlight {
		background-color: @warning3;
		color: @warning1;
		label.htmlLabel {
			p {
				a:active, a:visited, a:link, a:hover {
					color: @warning1;
					text-decoration: underline;
				}
			}
		}
	}
}

.infoPanel-alt {
	display: flex;
	align-items: flex-start;
	gap: 12px;
	vertical-align: top;
	padding: 16px;
	border-radius: 2px;
	border: 1px solid  @tertiary21;
	background-color: @tertiary24;

	> .icon-info-alt:before {
		font-size: 20px;
		color: @tertiary21;
	}
	
	p {
		margin: 0px 0px;
	}
}

.warningPanel {
	display: flex;
	align-items: center;
	gap: 10px;
	vertical-align: top;
	padding: 8px;
	border-radius: 3px;
	border: 1px solid @warning1;
	background-color: @warning3;

	> .icon-cross-alt:before {
		font-size: 25px;
		color: @warning1;
	}
}

.paginamelding > .infoPanel {
	margin-top: 10px;
}

.info {
	font-family: @font-family;
}
