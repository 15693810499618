@import "../core/_colors";

DIV.primaryMenu {
	font-size: 14px;
	font-weight: @medium;
	margin-bottom: 15px;
	padding-top: 5px;
	
	&>SPAN {
		cursor: pointer;
		color: @font4;

		&:not(:first-child):before {
			margin-left: 15px;
			margin-right: 15px;
			font-family: @icomoon-font-family;
			content: @icon-angle-right;
			vertical-align: -10%;
			color: @font4;
		}

		&.selectedMenuitem {
			color: @primary1;
		}
	}
	
	.laatstOpgeslagenInfo {
		float: right;
		color: @font4;
	}
}

DIV.menuContainer {
	min-width: 100%;
	margin-right: 5px;

	.secondaryMenu {
		overflow: auto;
  		white-space: nowrap; 		
		display: flex;
		align-items: center;
		height: auto;
		background: @white;
		border: 1px solid @greyscale5;
		border-radius: 4px 4px 0 0;

		SPAN {
			cursor: pointer;
			padding-left: 10px;
			padding-right: 10px;
			line-height: 40px;

			&:hover {
				color: @secondary1;
			}

			&.selectedMenuitem {
				color: @primary1;
				font-weight: @medium;
				border-bottom: 3px solid @primary1;
				align-items: center;
			}
		}
	}

	.tertiaryMenu {  
		overflow: auto;
  		white-space: nowrap;
		display: flex;
		align-items: center;
		height: auto;
		background: @greyscale8;
		border: 1px solid @greyscale5;
		border-radius: 0 0 4px 4px;

		SPAN {
			cursor: pointer;
			padding-left: 10px;
			padding-right: 10px;
			line-height: 40px;
			
			&.selectedMenuitem {
				color: @primary1;
				font-weight: @medium;
			}
			
			&:hover {
				color: @secondary1;
			}
		}
	}
}

.menuContainer.menuContainerStandaardteksten {
	font-size: 14px;
}

.menuContainer.menuContainerOntwikkelperspectief {
	margin-top: -17px;
	margin-left: -1px;
	
	.secondaryMenu{
		border-radius: 0;
		white-space: nowrap;
	}
	
	.content-container-order-select {
		margin-top: 0;
	}
}