.radiogroup {
	display: flex;
	justify-content: space-between;
	padding-left: 12px;
	
	.radio-container {
		flex: 1;
		cursor: pointer;
		margin-left: -10px;
		display: flex;
		align-items: center;

		/* remove all browser styling from the radio button */
		input[type="radio"] {
			display: none;
		}

		input[type="radio"] + span::before {
			font-family: @icomoon-font-family;
			content: @icon-radio-unchecked;
			color: @font4;
			font-size: 25px;
			margin: 0 5px 0 0;
			vertical-align: top;
		}

		input[type="radio"]:checked + span::before {
			content: @icon-radio-checked;
			color: @primary1;
		}

		input[type="radio"]:disabled + span {
			cursor: default;
			color: @greyscale6;
		}

		input[type="radio"]:disabled + span::before {
			cursor: default;
			color: @greyscale6;
		}
		
		&.disabled_radio {
			cursor: default;
			color: @greyscale6;
			
			input[type="radio"] + span::before {
				color: @greyscale6;
			}
			
			input[type="radio"]:checked + span::before {
				color: @greyscale6;
			}		 
		}
	}
}

.radioOnderElkaar .radiogroup {
	flex-wrap: wrap;
	
	.radio-container {
		flex-basis: 100%;
		margin-bottom: 8px;
	}
}