/** Begin Collapsible boom  in vragenlijsten*/
.collapsibleTreePanel {
	padding-left: 10px;
	padding-right: 10px;
	ul,
	li {
		list-style: none;
		padding-left: 0px;
	}
}

.collapsible_flex {
	display: flex;
	align-items: center;
}

.collapsibleTreePanel .nodeHeader > div > div.rubriekNaam {
	width: 328px;
}

.collapsibleTreePanel
	> div
	> ul
	> li
	> div
	> div
	> .nodeHeader
	> div
	> div.rubriekNaam {
	width: 358px;
}

.rubriekResult {
	float: left;
	text-align: center;
	width: 40px;
	margin-left: 30px;
	margin-right: 30px;
}

.collapsibleTreePanel > div > ul > li > div > div > .nodeHeader .rubriekResult {
	width: 100px;
	margin-left: 0px;
	margin-right: 0px;
}

.collapsible_header {
	cursor: pointer;
	font-family: @icomoon-font-family;
}

.collapsible_header_open:before {
	.collapsible_header;
	content: @icon-remove;
	color: @primary1;
	font-size: 20px;
	padding-left: 4px;
}

.collapsible_header_closed:before {
	.collapsible_header;
	content: @icon-add;
	color: @primary1;
	font-size: 20px;
	padding-left: 4px;
}

.icon_up {
	.collapsible_header;
}

.icon_down {
	.collapsible_header;
}

.icon_down:hover,
.icon_up:hover {
	text-decoration: none;
}

.collapsible_content {
	padding-left: 30px;
	padding-top: 8px;
	margin-bottom: 16px;
	div.content {
		.collapsible_flex;
	}
}

.collapsible_content a.addprop,
.collapsible_content a.newcat {
	display: block;
}

.collapsible_content a.delprop {
	margin-right: 10px;
}

.collapsible_node {
	position: relative;
	clear: left;
	margin-bottom: 4px;
}

.nodeHeader > input[type="checkbox"] {
	position: absolute;
	top: -2px;
}

.collapsible_label {
	padding-left: 4px;
	color: @font1 !important;
	.collapsible_flex;
	margin-bottom: 8px;
}

.nodeHeader {
	display: inline;
}

.nodeHeader > span.selectableNodeLabel {
	padding-left: 20px;
}

.collapsibleTreePanel > .collapsibleTreeType_select .collapsible_vragen {
	margin-left: 10px;
}

.collapsible_vragen > li {
	margin-left: 15px;
}

.collapsible_vragen > li > div.vraagSelectPanel {
	padding-left: 20px;
}
.collapsible_vragen > li > div.vraagSelectPanel > input[type="checkbox"] {
	margin-left: -16px;
}

.collapsible_button {
	float: left;
}

.orderContainer {
	width: 22px;
	float: left;
	.collapsible_flex;
}

.collapsible_mainheader, .labeltje {
	.collapsible_flex;
}
