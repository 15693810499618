#dossier-inzage {

	h1, h2 {
		font-weight: 500;
	}

	.container {
		display: flex;
		gap: 40px;
		margin-top: 24px;

		.keuzes {
			display: grid;

			.vervolgschool {
				margin-top: 40px;

				.vervolgschoolTekst {
					padding: 8px;
				}

				.vervolgschoolSelectie {
					margin-top: 12px;
				}
			}
		}

		.fieldsetcontent {
			flex: 1 0 0;
			background: @white;
			border: 1px solid @greyscale7;
			padding: 12px;
			height: 100%;

			&.vervolgschool {
				height: auto;
			}

			.fieldsetbox {
				box-shadow: none;

				textarea {
					width: 100%;
				}
			}

			&.info {
				background-color: @tertiary24;
				border: 1px solid @tertiary23;
				border-radius: 4px;
				padding: 11px;
				max-width: 50%;

				h2 {
					margin: 8px 0;
				}

				p {
					margin: 0;
				}
			}
		}

		.inzageBestandContainer {
			display: flex;
			background-color: @greyscale7;
			gap: 5px;
			border-radius: 4px;
			border: 1px solid @greyscale4;
			padding: 20px;

			.icon-text-file {
				font-size: 30px;
				color: @primary1;
			}

			.bestandInfo {
				display: flex;
				flex-direction: column;
				line-height: 20px;

				.bestandsnaam {
					font-style: italic;
				}
			}

			.button {
				display: flex;
				padding: 0 30px;
				align-items: center;
				font-weight: 500;
				color: @primary1;
				border: 2px solid @primary1;
				border-radius: 4px;
				cursor: pointer;
				margin-left: auto;

				.icon-download {
					font-size: 18px;
					padding-left: 10px;
				}
			}
		}

		.inzageEnAkkoordVerzorgers {
			label {
				display: inline-block;
				margin-bottom: 3px;
			}
		}
	}

	.volledigGevuldMelding {
		position: fixed;
		bottom: 60px;
		left: 16px;
		right: 16px;
		align-items: center;
		padding: 10px;
		background: @tertiary44;
		border: 1px solid @tertiary43;
		border-radius: 4px;
		color: @black;

		span {
			color: @tertiary41;
			margin-right: 10px;
		}
	}

	.volledigGevuldMeldingInner {
		left: 32px;
		background: @white;

		span {
			color: @black;
			margin-right: 10px;
		}
	}
}

@media (max-width: 1023px) {
	.container {
		flex-direction: column;
	}
}
