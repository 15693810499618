.toetsen-edit-table {
	height: ~"calc(100vh - 300px)";
	position: relative;
	overflow-y: auto;
	
	TABLE.fortytwoTbl {
		text-align: left;
		
		.niveauA,
		.niveauB,
		.niveauC,
		.niveauD,
		.niveauE,
		.niveauZien,
		.niveau_backgroundcolorless,
		.niveau_empty {
			padding: 12px 3px;
		}
		
		.iconClick {
			font-size: @font-size + 6px;
		}
		
		TD SPAN {
			padding: initial;
		}
		
		TD:first-child {
			padding-right: 0px;
		}
		
		THEAD {
			TR:nth-child(even) {
				background-color: @greyscale8;
			}
			
			TR:first-child TH {
				padding-left: 5px;
			}
			
			TR:last-child {
				box-shadow: 0px 8px 12px -10px rgba(0,0,0,0.45);
				TH {
					.topBoxShadow;
				}
				TH:last-child {
					.innerRightTopBoxShadow;
				}
			}
			
			TR TH:last-child {
				.innerRightBoxShadow;
			}
			
			TH {
				border-right: unset !important;
				font-weight: 500;
    			height: 40px;
			}
			
			TH:first-child {
				padding-right: 0px;
				border-right: none;
			}
		}
		
		TBODY {
			line-height: 0;
			
			TR:last-child TD {
				.topBottomBoxShadow;
			}
		}
	}

	&.scrollableTbl {
		TH,
		TD {
			border-top: unset;
		}
		
		TR:not(:last-child) {
			.fixedColumn:first-child {
				.topBoxShadowOverwritable;
			}
			
			.fixedColumn:nth-child(2),
			.fixedColumn:nth-child(3) {
				.topBoxShadow;
			}
		}
	}
}

.toetsen-table {
	.toetsen-edit-table;
	
	TABLE.fortytwoTbl {
		TH DIV {
			font-weight: 500;
			font-size: @font-size;
		}
	}
	
	&.scrollableTbl {
		TR:not(:last-child) {
			.fixedColumn:nth-child(4) {
				.topBoxShadow;
			}
		}
	}
}

.toetsen-vergelijken-table {
	.toetsen-edit-table;
	
	TABLE.fortytwoTbl {
		border-collapse: separate;
		margin-top: -1px;
		
		.niveauA,
		.niveauB,
		.niveauC,
		.niveauD,
		.niveauE,
		.niveauZien,
		.niveau_backgroundcolorless,
		.niveau_empty {
			padding: 3px 3px;
		}
		
		THEAD {
			.noBoxShadow;
			TH DIV {
				padding-top: 0;
				font-weight: 500;
				font-size: @font-size;
			}
		}
	
		TBODY {
			line-height: initial;
			
			TD > DIV {
				padding: 6px 0px;
			}
		}
	}
	
	&.scrollableTbl {
		TR:first-child TH {
			border-top: 1px solid @greyscale5;
		}
	}
}