#groepsautorisatie-edit-panel {
	.fields {
		background-color: @white;
		box-shadow: 0 0 8px rgba(13, 13, 13, 0.12), 0 0 16px rgba(13, 13, 13, 0.08);
		padding-bottom: 8px;

		.infoPanel {
			margin: 0 8px;
		}

		.fieldsetbox {
			box-shadow: none;
			margin-bottom: 0;
		}

		.inpSelect {
			width: 420px;
		}
	}
}

.privacybasisBannerLogo {
	display: flex;
	align-items: center;
	font-size: 16px;
	font-weight: 500;
	gap: 8px;
	padding-bottom: 4px;
}

.forty-two-launch-link {
	content: url("@{images}/fortytwo-icons/icon-launch.svg");
	width: 20px;
	height: 20px;
	transform: translateY(4px);
}