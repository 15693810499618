.kolommenkiezer-body {
	border-bottom: 1px solid @greyscale5;
}

.kolommenkiezer-content {
	min-width: 502px;
	font-size: @font-size + 2px;
	.font-bold;
	
	.order-item-header {
		font-size: @font-size;
		.font-bold;
	}
}