
/* autoforms */

.required:not(label) {
	background-color: @tertiary34;
	border: 1px solid @tertiary31 !important;
}

input:focus-visible {
	border: 1px solid @primary1;
	outline: none;
}

.inpText,
.inpSelect {
	width: 244px;
	border-radius: @border-radius-large;
	border: 1px solid @greyscale3;

	&.hasDatepicker,
	&.ui-autocomplete-input {
		width: 208px;

		&.simpelDatumVeld {
			width: 244px;
		}
	}
}

.inpSelect {
	width: 262px;
}

.inpQuickSearch {
	display: flex !important;
	align-items: center;
}

.inpTime {
	width: 45px;
	border-radius: @border-radius-large;
	border: 1px solid @greyscale3;
}

input.inpCheck {
	margin-left: 0px;
	vertical-align: middle;
}

textarea.width_630 {
	width: 630px;
}

textarea.height_200 {
	height: 200px;
}

textarea {
	width: 400px;
}

[maxlength] {
	background-position: left top;
	background-repeat: no-repeat;

	background-image: -webkit-linear-gradient(
		left,
		rgba(215, 21, 146, 0),
		rgba(215, 21, 146, 1)
	);
	background-image: -moz-linear-gradient(
			left,
			rgba(215, 21, 146, 0),
			rgba(215, 21, 146, 1)
		),
		-moz-linear-gradient(left, rgba(215, 21, 146, 0), rgba(215, 21, 146, 1));
	background-image: -o-linear-gradient(
			left,
			rgba(215, 21, 146, 0),
			rgba(215, 21, 146, 1)
		),
		-o-linear-gradient(left, rgba(215, 21, 146, 0), rgba(215, 21, 146, 1));
	background-image: linear-gradient(
			left,
			rgba(215, 21, 146, 0),
			rgba(215, 21, 146, 1)
		),
		linear-gradient(left, rgba(215, 21, 146, 0), rgba(215, 21, 146, 1));
	background-size: 0% 2px;

	transition: background-size 0.2s;
	-moz-transition: background-size 0.2s;
	-webkit-transition: background-size 0.2s;
	-o-transition: background-size 0.2s;
}

.bronfieldsetcontent {
	display: block !important;
}

.noPadding {
	padding: 0px;
}

// FLAM-1242, FLAM-1256 layout bewerken (ckeditor) en inzien van opgeslagen html gelijktrekken
.htmlLabel {
	p {
		margin: 0;
	}

	h1 {
		margin-left: 0;
		font-size: 2em;
		line-height: 1.2em;
	}

	h2 {
		margin-left: 0;
		font-size: 1.5em;
		line-height: 1.2em;
	}

	h3 {
		margin-left: 0;
		font-size: 1.17em;
	}
}
