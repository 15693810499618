/* Aanpassingen bovenop vendor jquery-ui stijl meer aan te sluiten bij ParnasSys*/

.ui-dialog-buttonset .ui-corner-all {
	.rounded-corners(40px);
}

.ui-widget-header {
	&.ui-progressbar-value {
		background: @primary3 !important;
	}
	background: @white !important;
	color: @font2 !important;
	border: 0;
	border-bottom: 1px solid @greyscale5;
	border-radius: 0;
	padding: 6px;
	margin: 0;
}

.ui-widget-content {
	background-color: @white !important;
}

.ui-widget-header .ui-icon {
	background-image: url("@{images}/jquery-ui/ui-icons_ffffff_256x240.png") !important;
}

.os-add.ui-icon,
.os-down.ui-icon,
.os-up.ui-icon,
.os-remove.ui-icon {
	width: 16px;
	height: 16px;
	background-size: 12px;
	margin-top: 5px !important;
}

.os-add.ui-icon {
	.icon-common-add;
}

.os-down.ui-icon {
	.icon-nav-arrowdown;
}

.os-up.ui-icon {
	.icon-nav-arrowup;
}

.os-remove.ui-icon {
	.icon-common-remove;
}

.os-orderselect {
	.os-name {
		font-size: @font-size !important;
		text-overflow: ellipsis !important;
	}
	.os-available .os-name {
		width: 340px !important;
	}
}

ul.os-orderselect li.ui-sortable-handle {
	height: 26px;
	line-height: normal;
	background: none;
}

.modal-dialog-body {
	.os-label, ul.os-orderselect {
		width: 410px !important;
		background-color: @greyscale8 !important;
		li.ui-state-default.ui-corner-all.ui-sortable-handle {
			background-color: @white !important;
		}
	}
}

.ui-dialog .ui-widget-header .ui-state-hover {
	border: none !important;
	background: none !important;
}

.ui-dialog .ui-dialog-titlebar-close:hover,
.ui-dialog .ui-dialog-titlebar-close:focus {
	padding: 1px !important;
	border: none;
	background-color: @white;
}

.ui-dialog .ui-dialog-titlebar-close {
	top: 12px !important;
	border: none;
	background-color: @white;
	background-image: url("@{images}/fortytwo-icons/icon-nav-cross.svg") !important;
	background-repeat: no-repeat;
	background-position: center center;
	width: 32px;
	height: 32px;
	content-visibility: hidden;
	color: transparent;
	&:focus-visible {
		outline: none;
	}
}

.ui-icon-help {
	background-image: url("@{images}/fortytwo-icons/icon-info-alt.svg") !important;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 14px;
	width: 32px;
	&:focus-visible {
		outline: none;
	}
}

.ui-widget {
	font-family: @font-family;
	z-index: 30000;
}

.ui-widget input,
.ui-widget select,
.ui-widget textarea,
.ui-widget button {
	font-family: @font-family;
	top: 5px !important;
	margin-right: 8px;
}

.ui-widget-overlay {
	opacity: 0.5 !important;
}

.ui-state-default,
.ui-widget-header .ui-state-default,
.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
	border: @dungrijs !important;
	color: @font1 !important;
}

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-active .ui-state-hover,
.ui-widget-content .ui-state-active .ui-state-hover,
.ui-widget-header .ui-state-active .ui-state-hover {
	cursor: pointer;
	color: @font1 !important;
	background: @greyscale7;
	border-radius: 0;
	margin: 0;
}

button.ui-state-hover {
	border-radius: @border-radius-large;
}

button.ui-state-active {
	border-top-left-radius: @border-radius-large;
	border-top-right-radius: @border-radius-large;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	border-color: @black !important;
}

.ui-multiselect {
	height: 32px;
	font-size: @font-size !important;
	padding: 6px 8px;
	text-align: left;
	background: @white !important;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	&.ui-widget.ui-state-default {
		border: 1px solid @greyscale3 !important;
	}
	&.ui-widget.ui-state-default.ui-state-active {
		border: 1px solid @black !important;
	}
}

.ui-multiselect-menu {
	padding: 0;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	border-top-width: 0 !important;
}

.ui-multiselect-checkboxes {
	li {
		margin: 0;
		padding: 0;
		border: 0;
	}
	label {
		display: flex;
		align-items: center;
		margin: 0;
		padding: 4px;
		border: 0 !important;
		input {
			position: unset !important;
		}
	}
	label.ui-corner-all {
		display: flex;
	}
}

.toetsversieselect .ui-multiselect {
	padding: 9px 7px 9px 4px;
}

.toetsversieselect .ui-multiselect.ui-widget.ui-state-default {
	border: 1px solid @greyscale3 !important;
	&.ui-state-active {
		border: 1px solid @black !important;
	}
}

span.ui-icon {
	margin-top: 0;
}

.ui-shortselectname ~ .ui-multiselect span.ui-icon ~ span {
	display: block;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

// FortyTwo styling voor multiselect
.ui-multiselect-header ul {
	font-size: 0.9em;
}

.ui-multiselect-header ul li {
	float: left;
	padding: 0 10px 0 0;
	list-style: none;
}

.ui-multiselect-header,
.ui-datepicker-header {
	a {
		color: @font1;
		text-decoration: none;
	}

	a:hover {
		text-decoration: none;
		color: @secondary1;
	}

	span.ui-icon {
		float: left;
	}

	li.ui-multiselect-close {
		display: none;
		float: right;
		text-align: right;
		padding-right: 0;
	}
}

.ui-multiselect {
	span.ui-icon {
		float: right;
		padding-right: 4px;
		background-image: url("@{images}/fortytwo-icons/icon-caret-down.svg");
		background-repeat: no-repeat;
		background-position: center;
		background-size: 10px;
	}
}

a.ui-multiselect-all {
	span.ui-icon {
		background-image: url("@{images}/fortytwo-icons/icon-check.svg") !important;
		background-repeat: no-repeat;
		background-position: center;
		background-size: 10px;
	}
}

a.ui-multiselect-none {
	span.ui-icon {
		background-image: url("@{images}/fortytwo-icons/icon-nav-cross.svg") !important;
		background-repeat: no-repeat;
		background-position: center;
		background-size: 9px;
	}
}

.ui-multiselect-menu.ui-widget.ui-widget-content.ui-corner-all {
	border: 1px solid @black;
}

// FortyTwo styling voor datepicker
a.ui-datepicker-prev {
	width: 16px !important;
	top: 6px !important;
	span.ui-icon {
		background-image: url("@{images}/fortytwo-icons/icon-nav-left.svg") !important;
		background-repeat: no-repeat;
		background-position: center;
		background-size: 10px;
	}
	&.ui-datepicker-prev-hover {
		background: none;
		border: none;
	}
}

a.ui-datepicker-next {
	width: 16px !important;
	top: 6px !important;
	span.ui-icon {
		background-image: url("@{images}/fortytwo-icons/icon-nav-right.svg") !important;
		background-repeat: no-repeat;
		background-position: center;
		background-size: 10px;
	}
	&.ui-datepicker-next-hover {
		background: none;
		border: none;
	}
}

.ui-multiselect-required {
	background: #c6e7df !important;
}

a.ui-state-default {
	text-align: center !important;
	vertical-align: middle;
}

.ui-datepicker-calendar {
	.ui-state-default {
		background-color: @white !important;
		background: none !important;
	}
	.ui-state-active {
		background: none !important;
		background-color: @primary1 !important;
		border: 1px solid @primary1 !important;
		color: @white !important;
	}
	.ui-state-highlight {
		background: none !important;
		background-color: @primary3 !important;
		border: 1px solid @primary3 !important;
		color: @font1 !important;
	}
}

.ui-datepicker td {
	border-collapse: collapse;
	padding: 0;
}

.ui-datepicker {
	box-shadow: 0px 6px 14px -6px rgba(13, 13, 13, 0.12),
		0px 10px 32px -4px rgba(13, 13, 13, 0.1);
	border: 1px solid @greyscale6 !important;
	padding: 16px;
	z-index: 1001 !important;
}

.ui-datepicker-header {
	border: none !important;
}

.ui-datepicker-buttonpane {
	border: none !important;
	button.ui-state-default {
		background: none !important;
		text-decoration: none;
		border: none !important;
		color: @white !important;
		border-radius: 4px;
		font-size: @font-size;
		font-weight: @medium;
		white-space: nowrap;
		cursor: pointer;
		margin-right: 8px;
		padding: 8px 12px;

		a {
			text-decoration: none;
			color: @white;
		}

		&.ui-datepicker-current {
			background-color: @primary1 !important;
			opacity: 1;
			box-shadow: inset 0px -3px 0px rgba(13, 13, 13, 0.25);
			&:hover {
				color: @font5;
				background-color: @primary2;
				box-shadow: inset 0px -3px 0px rgba(13, 13, 13, 0.25);
			}
		}

		&.ui-datepicker-close {
			color: @font3 !important;
			background-color: @white;
			border: 2px solid @font3 !important;
			box-shadow: none;
			padding: 6px 10px;
			&:hover {
				color: @font2 !important;
				border: 3px solid @font2 !important;
				padding: 5px 9px;
			}
		}
	}
}

// FortyTwo styling voor tabs
.ui-tabs .ui-tabs-nav li {
	background: none;
	cursor: pointer;
	&.ui-state-active {
		border-bottom: 1px solid @white !important;
		a {
			color: @primary1 !important;
		}
	}
	&.ui-state-hover {
		border-top-left-radius: @border-radius-large;
		border-top-right-radius: @border-radius-large;
		a {
			color: @secondary1 !important;
		}
	}
}

ul.ui-tabs-nav {
	padding: 0.2em 0 0 0 !important;
	margin-bottom: -1px !important;
}

// FortyTwo styling voor pills (bijvoorbeeld bij sociogrammen)
textarea.select2-search__field {
	line-height: 20px;
	font-family: @font-family !important;
}

button.select2-selection__choice__remove {
	color: white !important;
	border: none !important;
	padding: 2px 4px !important;
	font-size: 16px !important;
	background-color: @primary1 !important;
	:hover,
	:focus,
	:focus-within {
		background-color: @primary1 !important;
		border: none !important;
	}
}

li.select2-selection__choice {
	border-radius: 20px !important;
	background-color: @primary1 !important;
	border: none !important;
	color: white;
	height: 20px;
	line-height: 20px;
	margin-bottom: 4px !important;
}

// FortyTwo styling voor accordion-structuur
.ui-accordion {
	.ui-accordion-header {
		background: @greyscale7;
		margin: 0;
		padding-left: 4px;
	}
}

.ui-icon-triangle-1-s {
	background-image: url("@{images}/fortytwo-icons/icon-caret-down.svg") !important;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 12px;
	padding-left: 4px;
}
.ui-icon-triangle-1-e {
	background-image: url("@{images}/fortytwo-icons/icon-nav-right.svg") !important;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 8px;
	padding-left: 4px;
}
