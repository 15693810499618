// style.less is nu de verzamelbak voor alle incidentele / losse styling in ParnasSys.
// In een ideale wereld is deze pagina leeg (maar in onze wereld bevat 'ie ~1000 regels code...)

.mainheader h2 {
	color: @font1;
	font-size: @font-size;
	line-height: 28px;
	font-weight: 400;
	margin: 0;
	padding-top: 8px;
	padding-left: 8px;
}

.contentTitle {
	margin: 0 0 8px 0;
}

.contentArea {
	.mixin-box-shadow (1px -1px 1px #d4dbda);
	z-index: 1;
	padding: 0 16px;
	margin-bottom: 48px;
	background-color: @greyscale8;
	overflow-y: auto;
	position: relative;
	height: inherit;
}
.arrow {
	cursor: default !important;
}

// leerling snelzoeken

.leerlingsnelzoeken {
	> ul.select2-results {
		max-height: 385px !important;

		.select2-highlighted {
			background-color: @gray-70;
		}
	}

	.select2-result-label {
		height: 70px;

		.foto {
			float: left;
			padding-right: 10px;
			height: 70px;
			width: 52px;
		}

		> div {
			clear: left;
		}

		.naam {
			font-size: 20px;
			padding-top: 10px;
		}

		.status {
			float: right;
			padding-right: 20px;
		}

		.groep {
			float: left;
		}

		.subtext {
			padding-top: 7px;
		}
	}
}

td.selection {
	text-align: right;
	color: @gray-60;
	line-height: 20px;
	font-weight: bold;
	padding-right: 15px;
	padding-top: 8px;
	img {
		cursor: pointer;
		vertical-align: middle;
	}
}

td.content {
	background-color: @white;
}

tr.line hr {
	color: @gray-30;
	height: 1px;
	border-style: dotted;
}

/* Zoekpaneeltje met achtergrondverloop. */
table.search {
	background-color: @gray-20;
	border: @dungrijs;
	box-shadow: 1px 1px 1px @gray;
	border-radius: 3px;
	padding: 8px;

	.main_label_no_width,
	.table search .main_label {
		font-weight: normal;
		color: @gray-60;
		padding-left: 5px;
		padding-right: 5px;
	}

	td {
		padding-left: 5px;
		padding-right: 5px;
	}
}

table.bodybox {
	border: 1px solid @gray-40;
	background-image: url("@{images}/bg_btn_input_sqare.png");
	background-repeat: repeat-x;
	.rounded-corners(4px);
}

/* FORM ELEMENTS */
/* De standaard input als er geen class gedefinieerd is.*/

input.uploadform {
	height: 25px;
	font-size: 1.1em;
}

.textarea_no_resize {
	resize: none;
}

select.halfWidth {
	width: 49%;
	display: inline-block;
}

/* Context is de stijl van de balk waarin de context wordt weergegeven, deze dient toegekend te worden aan een TD */
.context {
	background: @gray-40;
	padding-right: 15px;
	padding-left: 25px;
	color: @font1;
	font-weight: bold;
}

.action {
	padding-left: 0px;
	height: 28px;
	color: white;
	border: 0;
}

/* Buttonline is de stijl die aan de TD waarin de buttons staan toegekend moet worden */
.buttonline {
	border: none;
	padding-right: 25px;
	padding-left: 25px;
	font-size: @font-size + 2px;
	color: @color-brand;
	font-weight: bold;
	height: 30px;
}

.buttonline span {
	padding: 0px !important;
}

.message_table {
	background: @gray-40;
	height: 100%;
	width: 100%;
}

.melding {
	color: @tertiary31;
	padding: 4px;
}

.opmerkingLeerling {
	color: @tertiary41;
	font-size: 15px;
}

.messagebar {
	padding-top: 0px;
}

.error {
	color: @color-brand !important;
}

.main_error,
.feedbackPanelERROR {
	padding-top: 10px;
	color: @color-brand;
	font-size: @font-size;
}

/* Opmaak voor het absentieoverzicht per groep, de volgende aan-/afwezigheid is gedefinieerd:
* aanwezig = aanwezig
* gafwezig = geoorloofd afwezig
* oafwezig = ongeoorloofd afwezig
* vrij = vrij
* de styles worden toegepast op een td
*/

.absentie(@color) {
	color: black;
	border: @dunwit;
	background: @color;
}

.aanwezig {
	.absentie(@tertiary34);
}

.gafwezig {
	.absentie(@tertiary44);
}

.oafwezig {
	.absentie(@warning3);
}

.anderegroep {
	.absentie(@tertiary24);
}

.schoolvakantie {
	color: @gray-70;
	border: @dunwit;
	background: @gray-40;
}

/* Kalender stijlen voor jaarplan */
.kalender {
	border-collapse: collapse;
}

.kalender .header {
	color: @white;
	font-weight: @medium;
	background: @greyscale5;
	border: 0;
}

.kalender .main_label {
	font-style: italic;
}

.kalender td {
	border: 1px solid @greyscale8;
}

.kalender .dag {
	float: right;
}

.kalender .andere_maand {
	color: @greyscale4;
}

.vrijdagdeel {
	background-color: @warning3;
}

.vrijedag {
	background-color: @primary3;
}

.gewoon {
	background-color: @white;
}

.weekend {
	background-color: @tertiary24;
}

.activiteit {
	cursor: pointer;
	float: bottom;
	display: block;
}

.activiteit-publiek {
	.activiteit;
	font-weight: @medium;
}

/* Style om een scheidinglijn aan te brengen tussen 2 kolommen, toepassen op een td*/
.solidcolseparator {
	font-size: 0px;
	padding: 0;
	width: 1px;
}

.dottedcolseparator {
	border-left: 1px dotted @black;
}

.observatielijstHoofdCategorie {
	border: 1px solid @gray-70;
}

.observatielijstHoofdCategorie_header {
	font-weight: bold;
	color: white;
}

.observatielijst-searchbar {
	display: flex;
	justify-content: flex-start !important;
	align-items: center;
}

a.linkAsLabel {
	color: black;
}

a.linkInActive {
	color: @font1;
}

.BOFrame {
	font-size: 8pt;
	padding: 0;
	overflow: hidden;
	width: 100%;
	position: relative;
	height: 100%;
	border: 0;
}

.scrollTable tbody {
	height: 400px;
	max-height: 400px;
	overflow: auto;
}

.scrollTable tfoot {
	overflow: auto;
}

.textInactive {
	text-decoration: none;
	color: @greyscale3;
}

.rightBorder {
	border-right: 1px dotted #d4dbda;
}

.displayTableCell {
	display: table-cell;
}

.displayTable {
	display: table;
}

.timeoutwarning {
	border-color: @gray-20;
	border-width: 0;
	background-color: @gray-20;
	color: Red;
	font-weight: bold;
	vertical-align: top;
}

.progress {
	.rounded-corners(10px);
	padding: 2px;
}

.thickBorder {
	border: 1px solid #9cadab !important;
}

.thickDottedBorder {
	border: 1px dotted #9cadab !important;
}

.green {
	background-color: @tertiary31;
}

.grey {
	background-color: @gray-60;
}

tr.white_bg_on_hover:hover {
	background-color: @white;
}

.hover-p2 {
	&:hover {
		color: @primary2;
	}
}

.hover-s1 {
	&:hover {
		color: @secondary1;
	}
}

// leerlijnen styling

.leerdoel_abstract {
	font-weight: bold;
	text-align: center;
	border: @dungrijs;
	.rounded-corners(4px);
	cursor: default;
}

.leerdoel_niet_gestart {
	.leerdoel_abstract;
	color: @white;
	background-color: @grade-yellow;
}

.leerdoel_gestart {
	.leerdoel_abstract;
	color: @white;
	background-color: @grade-orange;
}

.leerdoel_gepland {
	.leerdoel_abstract;
	color: @white;
	background-color: @grade-blue;
}

.leerdoel_behaald {
	.leerdoel_abstract;
	color: @white;
	background-color: @grade-green;
}

.leerdoel_afgevinkt_score_nul {
	.leerdoel_abstract;
	color: @white;
	background-color: @grade-orange;
}

.leerdoel_onbekend,
.leerdoel_niet_haalbaar {
	.leerdoel_abstract;
}

.beheermenuContainer {
	width: 500px;
	height: 620px;
	display: flex;
	flex-flow: column wrap;
}

.beheermenuContainer div {
	width: 240px;
	padding-right: 10px;
	flex: 0 1 auto;
}

div.centerContentVertically {
	display: flex;
	align-items: center;
}

.textcenter {
	text-align: center;
}

tr {
	th.thTextCenter {
		text-align: center;
	}
	td.thTextCenter {
		// webkit nodig om iconen te kunnen centreren
		text-align: -moz-center;
		text-align: -webkit-center;
	}
}

.text-right {
	text-align: right;
}

/** dit is non-logica door andere vorm van rendering van buttons als in andere cobra projecten **/
.below_5 {
	margin-bottom: 10px;
}

.colInputText input {
	width: 100%;
}

.collapsible_node .collapsible_content h3.caption {
	width: 100%;
	margin-bottom: 0px;
	color: @gray-70;
	font-size: @font-size + 2px;
	font-weight: @medium;
	padding-top: 0px;
	-webkit-margin-before: 0px;
	-webkit-margin-after: 0px;
	-webkit-margin-start: 0px;
	-webkit-margin-end: 0px;
}

textarea.openvraag_Textbox {
	height: 100px;
	width: 400px;
}

span.large > p,
span.extraLarge > p {
	margin-top: 0px;
}
span.large,
span.extraLarge {
	display: inline-block;
	width: 750px;
}

textarea.large {
	height: 132px;
	width: 100%;
	width: -moz-available; /* WebKit-based browsers will ignore this. */
	width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
	width: fill-available;
}

textarea.extraLarge {
	height: 400px;
	width: 750px;
}

.accordionHeaderLink {
	color: @color-brand;
}

ul.bad_password_messages {
	padding-left: 15px;
	border: 1px solid @color-brand;
	li {
		color: @color-brand;
		> span {
			color: @black;
		}
	}
}

#plattegrond {
	float: left;
	width: 960px;
	border: 1px solid @color-brand;
}

.warning-box {
	display: inline-block;
	padding: 5px;
	border: solid 1px @color-brand;
	p {
		display: inline-block;
		vertical-align: middle;
		margin: 0px auto;
	}
}

.stippellijn {
	width: 100%;
	border: 0;
	border-bottom: 1px dotted @gray-40;
}

.afbeeldingrondje {
	border-radius: 50%;
	border: 3px solid @color-brand-20;
}

.xml div.element {
	padding-left: 30px;
}

.xml span.name {
	display: inline-block;
	width: 200px;
	color: @gray-60;
}

span.error-text {
	color: @color-brand;
}

.center {
	text-align: center;
}

.marginAuto {
	margin: auto;
}

.navigator div {
	width: max-content;
	margin-left: auto;
	margin-right: auto;
}

.legenda_tekst {
	float: left;
}

.wupb-container {
	margin: 0 25%;
}

.wupb-uploadStatus {
	text-align: center;
}

.nowrap {
	white-space: nowrap;
}

/* Tabel met zoekresultaten */
.tblButtons a {
	font-size: @font-size + 2px;
	img {
		border: none;
		display: none;
	}
}

.sociogram-resultaten {
	text-align: center !important;
	vertical-align: middle;
	span {
		border: @dungrijs;
		border-radius: 3px;
		box-sizing: border-box;
		display: inline-block;
		font-weight: bold;
		height: 30px;
		width: 30px;
	}

	span.sociogram--geen {
		border: none;
	}

	span.sociogram--positief {
		background-color: @positive1;
	}

	span.sociogram--negatief {
		background-color: @negative1;
	}
}

// select2 4.0 input hoogte fixen
.select2-selection.select2-selection--multiple {
	min-height: 32px;
	max-height: 32px;
}

.select2-container--default .select2-selection--single {
	border: 1px solid @greyscale3 !important;
	border-radius: @border-radius-large;
	height: 32px !important;
	line-height: 32px !important;
}

.select2-container--default
	.select2-selection--single
	.select2-selection__rendered {
	color: @font1 !important;
	height: 32px !important;
	line-height: 32px !important;
}

.select2-container--default
	.select2-selection--single
	.select2-selection__placeholder {
	color: @font1 !important;
}

.select2-container--open .select2-dropdown--below {
	border: 1px solid @black !important;
}

.hidingButton {
	float: left;
	visibility: hidden;
	position: absolute;
	margin-top: 32px !important;
	height: 24px !important;
	width: 24px !important;
	line-height: 22px !important;
	margin-left: 2px;
}

.groepsgegevensverwijderenpanel {
	display: inline-block;
	padding-bottom: 10px;
}
.groepsgegevensverwijderenpaneltekst {
	padding-right: 5px;
}

//Styling toegevoegd ivm PAR-10548, het verwijderen van alle style-attributen uit de html voor de Wicket-9 migratie
.kleur-roze {
	color: #f65b83;
}
.kleur-groen {
	color: @tertiary31;
}
.kleur-donkergrijs {
	color: #394443;
}
.kleur-zwart {
	color: black !important;
}
.kleur-grijs {
	color: #777777;
}
.kleur-wit {
	color: #ffffff;
}
.fontweight_normal {
	font-weight: normal;
}
.contactnummerInput {
	width: 167px;
	margin-right: 7px;
}
.fontsize_14 {
	font-size: @font-size;
}
.datapanel_descriptionlabel {
	vertical-align: top;
	float: none;
}
.rooster_dagdeel_label {
	display: flex;
	align-items: center;
}
.rooster_info {
	display: inline-block;
	text-indent: 0px;
}
.leerkrachtrooster_dagheader {
	width: 17%;
	text-align: center;
}
.gemiddelde_background {
	background-color: @greyscale8;
}
.no_border_bottom {
	border-bottom: none !important;
}
.ultimview_link_page {
	width: 960px;
	margin: 20px auto;
}
.ultimview_logo {
	display: block;
	width: 220px;
	margin: 20px auto;
}
.lichtgrijze_rand {
	border: 1px solid #cccccc !important;
}
.witte_lijn {
	border-bottom: 1px solid #fff !important;
}
.aantal_rapportperiodes {
	float: left;
	padding-right: 8px;
	margin-top: 4px;

	INPUT {
		width: 60px;
	}
	// De bottomwrow met 'verwerken'-knop krijgt default andere margins
	+ span {
		.left {
			margin-top: 5px;
		}
	}
}
.leerjaren-select {
	button.ui-multiselect {
		width: auto !important;
	}
}
.toetsserie {
	padding: 20px 32px 20px 32px !important;
}
.momentPanel {
	position: absolute;
	border: 5px solid #5bb55b;
	background-color: white;
	z-index: 9999;
	display: none;
}
.zien_start_background {
	background-image: url("/bao/images/background.png");
}
.clear_left {
	clear: left;
}
.toets_required_field {
	background-color: #c6e7df !important;
	border: 1px inset #8fd1c1 !important;
}

.font_size_20 {
	font-size: 20px;
}

hr.invisible_horizontal_rule {
	border: none;
}

.display_inline {
	display: inline;
}
.background_sigmagreen {
	background-color: @tertiary44;
}
.disp_inlineblock_fullscreen {
	display: inline-block;
	width: 100%;
	height: 100%;
}
.rod_kolommen {
	display: flex;
}
.rod_kolom {
	flex: 50%;
	padding: 10px;
}
.rod_kolom_scheiding {
	border-right: 1px solid black;
}
.rod_tekst {
	font-weight: normal;
	font-size: 12px;
}

.plattegrond_setting {
	color: @primary1 !important;
	border: 1px solid @primary1 !important;
}

.plattegrond_settings_widget_inner {
	background-color: @primary1 !important;
}

.plattegrond_settings_widget {
	border: 1px solid @primary1 !important;
}

.plattegrond_settings_widget_top {
	border: 1px solid @primary1 !important;
}

.leerlingtelling {
	.tblTag {
		width: 300px !important;
	}

	INPUT {
		width: 100px !important;
	}
}

.edex-opties {
	.tblInput {
		div {
			display: flex;
			align-items: center;
		}
	}
}

// Lesrooster styling
.lesrooster_table {
	div {
		line-height: 20px;
	}
	td,
	th {
		border-right: 1px solid @greyscale5;
	}
	.lesrooster_lesdaglabel {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

// Leerlingkaart styling
.leerlingkaart_flexcontainer {
	display: flex;
	flex-wrap: wrap;
	width: ~"calc(100vw - 34px)";
	table.tblData {
		margin-right: 0;
		td.tblTag {
			width: 160px;
		}
		td.tblInput {
			overflow-wrap: anywhere;
		}
	}
	.leerlingkaart_avatar {
		display: flex;
		margin-bottom: 0;
		padding-bottom: 0;
		padding-right: 8px;
		div {
			border: none;
			box-shadow: none;
		}
	}
	.leerlingkaart_foto {
		div {
			margin-bottom: 0;
		}
	}
}
.leerlingkaart_gridcontainer {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 16px;
}
.leerlingkaart_personalia_border {
	background-color: #ffffff;
	border-radius: @border-radius-large;
	box-shadow: 0px 0px 8px rgba(13, 13, 13, 0.12),
		0px 0px 16px rgba(13, 13, 13, 0.08);
	div.fieldsetbox {
		min-width: -webkit-fill-available;
	}
	td.tblTag {
		width: 120px;
	}
}
.leerlingkaart_verzorgers {
	h2:first-of-type {
		margin: 0 !important;
	}
}

// Panels naast elkaar tonen wanneer redelijkerwijs mogelijk (dus laptopschermen en groter)
@media screen and (min-width: 1024px) {
	.leerlingkaart_flexcontainer {
		> div.fieldsetcontent {
			max-width: 30vw;
		}
	}
}

// Bij kleinere schermen worden de tabellen juist wel onder elkaar getoond om het leesbaar te houden.
@media screen and (max-width: 1023px) {
	.leerlingkaart_gridcontainer {
		grid-template-columns: 1fr;
	}
}

// Bij kleine laptopschermen een kleinere labelbreedte hanteren
@media screen and (min-width: 1024px) and (max-width: 1100px) {
	.leerlingkaart_flexcontainer {
		td.tblTag {
			max-width: 120px;
		}
	}
}

// FortyTwo Leerlingpersonalia styling
.personalia_fieldset{
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 16px;
} 

@media screen and (min-width: 866px) {
	.personalia_fieldset {
		display: flex;
	}
}

@media screen and (min-width: 866px) {
	.adviesvo_fieldset {
		display: flex;
	}
}

.multilinetextfield {
	p {
		margin-bottom: 0;
	}
}

// FortyTwo styling voor OPP
.uitstroombestemming_dropdown {
	.tblTag {
		width: 215px;
	}
}
.opp-header {
	display: flex;
	font-weight: @medium;
	margin-top: 8px;
	margin-bottom: 8px;
	grid-gap: 4px;
	span {
		margin-top: 0;
	}
}

//FortyTwo styling voor SchoolVanHerkomstPanel
.schoolvanherkomst_fieldset div {
	margin-bottom: 0;
	padding: 0;
}

.mededeling-table {
	.tblTag {
		font-weight: bold;
		vertical-align: top;
		padding-right: 16px;
	}
}

// FortyTwo styling voor accordeon-structuur
.accordeon-icons {
	line-height: 28px !important;
	font-size: 18px;
	padding-right: 4px;
}

// FortyTwo styling voor drag and drop
.orderselect-drag {
	font-size: 30px !important;
	align-self: center;
}

.grotereOpmerking {
	width: 256px;
	height: 120px;
}
