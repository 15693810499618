.selectorContainer {
	display: flex;
	font-weight: @medium;
	word-wrap: break-word;

	> DIV {
		width: 14%;
		margin-right: 8px;
		display: flex;
		flex-direction: column;
		gap: 8px;
	}

	.selectorLevel {
		border: 1px solid @greyscale5;
		box-sizing: border-box;
		border-radius: 4px;
		padding: 8px;
	}

	.selectorBox {
		border: 1px solid @font2;
		box-sizing: content-box;
		border-radius: 2px;
		min-height: 60px;
		padding-left: 8px;
		padding-top: 8px;
		padding-bottom: 8px;
		padding-right: 20px;
		cursor: pointer;

		&:hover {
			padding-left: 7px;
			padding-top: 7px;
			padding-bottom: 7px;
			padding-right: 19px;
		}

		&--small {
			> div.selectorBox {
				min-height: 0;
			}
		}
	}

	.selected .selectorBox {
		padding-left: 7px;
		padding-top: 7px;
		padding-bottom: 7px;
		padding-right: 19px;
	}

	.container {
		display: block;
		position: relative;
		cursor: pointer;
		user-select: none;
	}

	.container input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	.container .selectorLevelText {
		display: inline-block;
		width: 100%;
	}

	.checkmark {
		position: absolute;
		top: 4px;
		right: -12px;
		height: 16px;
		width: 16px;
		border-radius: 50%;
		border: 1px solid @font2;
		box-sizing: border-box;
	}

	.selected .container .checkmark:after {
		display: block;
		position: absolute;
		right: 1px;
		top: -2px;
	}

	.container .checkmark:after {
		font-family: @icomoon-font-family;
		content: @icon-checkmark;
		color: @white;
		display: none;
	}
}

.selectorContainerBeheer {
	font-size: 14px;

	.selectorContainer .selectorBox {
		border: 1px solid @font3;

		&:hover {
			border: 1px solid @font1;
			padding-left: 8px;
			padding-top: 8px;
			padding-bottom: 8px;
			padding-right: 20px;
		}
	}

	INPUT {
		width: ~"calc(100% - 8px)";
		padding-left: 8px;
	}

	.active .selectorBox {
		border: 1px solid @primary1 !important;
	}

	LABEL.container {
		margin-left: 0px;
		color: @font3;

		DIV.omschrijving-gewijzigd {
			color: @font1;
		}
	}
}

.selectorContainer {
	.selectorBox:hover {
		border: 2px solid @primary1;
		color: @primary1;
		box-sizing: content-box;

		.checkmark {
			border: 1px solid @primary1;
		}
	}

	.selected .selectorBox {
		background: @primary4;
		color: @primary1;
		border: 2px solid @primary1;
	}

	.selected .container .checkmark {
		background-color: @primary1;
		border: none;
	}
}

.selectorHeader {
	display: flex;
	margin-top: 16px;
	margin-right: 16px;
	margin-bottom: 16px;

	.titel {
		margin-right: auto;
		margin-left: 0px;
	}

	.resetInvoer {
		margin-right: 8px;
		margin-left: auto;
		color: @primary1;
		font-weight: 500;
		cursor: pointer;
	}
}

.invoerHeader {
	font-weight: @medium;
	padding-left: 20px;
	padding-top: 16px;
	padding-bottom: 12px;
}

.invoerContainer {
	padding-left: 20px;

	span {
		font-weight: initial;
	}

	input {
		cursor: pointer;
		height: 32px;
		width: 430px;
		height: 32px;
		border-radius: 4px;
		border: 1px solid @greyscale3;
		box-sizing: content-box;
		margin: 4px;

		&:hover {
			border: 1px solid @primary1;
			color: @secondary1;
		}
	}
}
