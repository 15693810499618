.npunt_caption {
	text-align: center;
	font-family: @font-family;
}

.npunt_cijferbox {
	padding: 20px;
	font-size: 2em;
	font-family: @font-family;
	color: white;
	display: inline-block;
	vertical-align: middle;
	min-width: 35px;
	text-align: center;
	box-shadow: 5px 5px 5px #888888;
}

.npunt_cijferbox_normkleur_onbekend {
	.npunt_cijferbox;
	background-color: #ff0000;
	color: #394443;
	background-image: url(../images/bg_striped.png);
}

.npunt_graphic {
	display: inline-block;
	vertical-align: middle;
}

.npunt_score {
	text-align: center;
	color: #ffffff;
}

.npunt_border {
	border: 2px solid #000000;
}

.middle_anchor {
	text-anchor: middle;
}

.score_interval {
	stroke: #cfd5d5;
	stroke-width: 5;
}

.normkleur_onbekend_arcering {
	fill: url(#diagonalHatch);
}

.scorelijn {
	stroke-width: 2px;
	stroke: #808080;
	stroke-dasharray: 5px, 5px;
	fill: none;
}
