.toetsen.titel {
	font-size: 14px;
	padding-bottom: 15px;
}

.toetsen.titel.showInPrint {
	.toetsen.titel;
	padding-top: 15px;
}

.toetsen.subtitel {
	font-size: 13px;
	font-weight: lighter;
	line-height: 1.5;
	color: @gray-70;
	padding-bottom: 20px;
}

.toetsen.datuminput {
	max-width: 80px !important;
}

.toetsen.ruwescoreinput,
.toetsen.vaardigscoreinput {
	max-width: 50px !important;
}

.toetsen.datuminput,
.toetsen.beschikbaretoetsen,
.toetsen.ruwescoreinput,
.toetsen.vaardigscoreinput {
	border-radius: 3px;
	border: @dungrijs;
	padding: 2px 4px 0 4px;
}

.toetsen.maxscore {
	color: @font3 !important;
}

.toetsen.zoekresultaten {
	font-size: 15px;
	font-weight: bold;
	padding-top: 8px;
	display: flex;
	align-items: center;
	> span.aantal {
		font-weight: lighter;
	}
}

.grey-text {
	color: slategrey;
}
.greyfield {
	background-color: @greyscale6;
	color: slategrey;
}
.lightgreyfield {
	background-color: @gray-10;
	color: slategrey;
}

.tooltip-container {
	padding-bottom: 8px;
	width: max-content;
}

// Tooltip en contents: zie https://www.w3schools.com/css/css_tooltip.asp
.tooltip {
	font-weight: normal;
	position: relative;
	outline: none !important;
	cursor: pointer;
	&.info {
		display: inline-block;
		padding: 4px;
	}
}

.tooltip.info:hover {
	color: @primary2;
}

.tooltip-contents {
	position: absolute;
	z-index: 3; // z-index 1 = tabel; z-index 2 = vaste kolommen; z-index 3 = tooltip-contents: weergaveopties en enz.;
	opacity: 0;
	visibility: hidden;
	display: none;
	line-height: initial;
	cursor: initial;

	color: black;
	border-radius: 6px;
	padding: @tooltip-padding !important;
	border: @dungrijs;

	// Weergaveopties en invoerhulp
	&.weergaveopties,
	&.invoerhulp,
	&.leerjaarfilter {
		background-color: @gray-10;
		text-align: left;
		top: 100%;
		left: 50%;
		margin-left: -70px;
		margin-top: -30px;
	}
	&.weergaveopties {
		width: 221px;
	}
	&.leerjaarfilter {
		width: 300px;
	}
	&.invoerhulp {
		width: 500px;
		> .icon-primary {
			font-size: @font-size;
			display: inline-block;
			overflow: initial;
			padding-left: 0px !important;
		}
	}
	&.koppelicon,
	&.opmerking {
		background-color: @tertiary44;
	}
	&.koppelicon {
		text-align: center;
		height: fit-content;
		margin-left: -235px;
		margin-top: -55px;
		white-space: normal;
	}
	&.opmerking {
		position: absolute;
		font-size: @font-size !important;
		width: 350px;
		text-align: left;
		margin-left: -400px;
		margin-top: -195px;

		li {
			padding-bottom: 10px;
		}
	}

	ul {
		margin-left: -50px;

		li {
			list-style: none;

			textarea {
				width: 340px;
				height: 70px;
			}
		}
	}
}

.info-icon {
	font-size: 20px;
	color: @tertiary31;
	vertical-align: middle;
}

.koppel-icon {
	display: inline-block;
	color: @gray-60 !important;
}

.icon-primary.aan {
	background-color: @positive1 !important;
	color: @white !important;
	&:hover {
		background-color: @positive2 !important;
		box-shadow: 0 1px 1px @positive2 !important;
	}
}

.icon-primary.uit {
	background-color: @greyscale5 !important;
	&:hover {
		background-color: @greyscale4 !important;
		box-shadow: 0 1px 1px @gray-60 !important;
	}
}

// FLAM-118 laat een checkbox er uitzien als een radio-button.
// https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_custom_checkbox
/* Customize the label (the container) */
.checkbox_as_radiobutton_container {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 17px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* Hide the browser's default radio button */
.checkbox_as_radiobutton_container input {
	position: absolute;
	opacity: 0;
}

/* Create a custom radio button */
.checkbox_as_radiobutton {
	position: absolute;
	top: 0;
	left: 0;
	height: 15px;
	width: 15px;
	background-color: @white;
	border-radius: 50%;
	border: 1px solid rgba(212, 102, 131, 0.5);
}

/* On mouse-over, add a grey background color */
.checkbox_as_radiobutton_container:hover input ~ .checkbox_as_radiobutton {
	background-color: @gray-30;
}

/* When the radio button is disabled, add a grey background */
.checkbox_as_radiobutton_container input:disabled ~ .checkbox_as_radiobutton {
	opacity: 0.5;
	border: @dungrijs;
	background-color: @gray-30;
	cursor: default;
}

/* When the radio button is checked, add a magenta background */
.checkbox_as_radiobutton_container input:checked ~ .checkbox_as_radiobutton {
	background-color: @color-brand;
	border: solid @color-brand 1px;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkbox_as_radiobutton:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the indicator (dot/circle) when checked */
.checkbox_as_radiobutton_container
	input:checked
	~ .checkbox_as_radiobutton:after {
	display: block;
}

/* Style the indicator (dot/circle) */
.checkbox_as_radiobutton_container .checkbox_as_radiobutton:after {
	top: 6px;
	left: 6px;
	width: 3px;
	height: 3px;
	border-radius: 50%;
	background: white;
}

// FLAM-23
.geen-resultaat {
	color: @gray;
}
.inactief-met-resultaat {
	color: @greyscale3;
}

// FLAM-312, niveau en gecorrigeerde niveau achter elkaar aan tonen.
.groepsresultaatitems > span {
	white-space: nowrap;
}
.groepsresultaatitems {
	margin-bottom: 1px;
}
.groepskaart-teltnietmee {
	color: @gray;
	background: @gray-10;
}
.groepskaart-teltnietmee.niveau_backgroundcolorless {
	color: @white;
	background: @gray;
}

.toetsenModalWindow div {
	padding-top: 0;
	padding-right: 16px;
	padding-bottom: 16px;
	padding-left: 16px;
	> select {
		height: 32px;
		width: 262px;
		border: 1px solid @greyscale3;
		border-radius: @border-radius-large;
		background-color: @white;
	}
}

.tblDatagrid.toetsenvergelijk td > div {
	padding: 2px 0px;
}

.tblDatagrid.toetsentable td {
	white-space: nowrap;
}

.tblDatagrid.toetsenvergelijk th {
	white-space: nowrap;
}

.tblDatagrid.toetsen {
	width: auto;
}

.tblDatagrid.toetsen td {
	padding-left: 8px;
	padding-right: 7px;
}

.tblDatagrid.toetsen th {
	padding-left: 8px;
	padding-right: 7px;
	white-space: nowrap;
}

.toetsen .dottedcolseparator {
	border-left: 1px dotted @gray;
}

.normering_link {
	color: @positive1 !important;
	font: icon;
}

// FortyTwo styling voor MT toetsinvoer modal dialog waarbij de toets-dropdown required is.
// Doordat het een select2 is, kan dit niet met de gangbare required-styling.
.toetsdropdown_container {
	.select2-container--default .select2-selection--single {
		background-color: @tertiary34;
		border: 1px solid @tertiary31 !important;
		border-radius: @border-radius-large !important;
		&[aria-expanded="true"] {
			background-color: @white !important;
			border: 1px solid @black !important;
			border-bottom-left-radius: 0 !important;
			border-bottom-right-radius: 0 !important;
			border-bottom: 0px !important;
		}
	}
}

.toetsenModalWindow {
	img.ui-datepicker-trigger {
		margin-bottom: 2px;
	}
}

.modal-dialog .modal-dialog-body .extraInfoModalDialog {
	> DIV:first-child {
		color: @font3;
		font-size: 12px;
		line-height: 18px;
	}

	> DIV:nth-child(2) {
		.font-bold;
		font-size: @font-size;
		line-height: 20px;
	}
}
	
// Bij lege periode of schooljaar een wat breder select-vakje tonen
[data-key*="GroepToetsViewZoekFilter"] {
    min-width: 52px;
}
