.os-label {
	font-weight: bold;
	font-size: 15px;
	text-align: center;
}

.os-label , ul.os-orderselect.os-selection.os-order {
	list-style-type: none;
	background: @white;
	overflow-x: hidden;
	overflow-y: hidden;
	height: auto;
	padding: 0;
	margin: 0;
	width: 100%;
	width: -webkit-fill-available;
}

ul.os-orderselect.os-selection.os-order li.ui-order-list {
	margin: 0;
	padding: 0;
	width: 100%;
	height: 48px;
	cursor: pointer;
	display: flex;
	color: @font1;
	background: @white;
	border-top: 1px solid @greyscale5;
	box-shadow: 0px 4px 6px -4px rgba(13, 13, 13, 0.12), 0px 8px 8px -4px rgba(13, 13, 13, 0.08);

	&:nth-child(even), &:last-child {
		border-bottom: 1px solid @greyscale5;
	}
		
	&:nth-child(odd) {
		background: @greyscale8;
	}	
	
	&:hover {
		background-color: @primary4;
	}
}

ul.os-orderselect.os-selection.os-order li.ui-order-list div {
	display: flex;
}

ul.os-orderselect.os-selection.os-order li.ui-order-list div.iconDescribe.icon-secondary.drag-and-drop {
	width: 32px;
	height: 32px;
	margin-top: 9px;
}

ul.os-orderselect.os-selection.os-order li.ui-order-list div.order-data {
	justify-content: space-between;
	width: 100%;
	margin-right: 5px;
}

ul.os-orderselect.os-selection.os-order li.ui-order-list div.order-data span.dd-naam {
	margin: 16px 24px;
	flex: 1;
}

div.order-item-header {
	margin-left: 32px;
	display: flex;
}

div.order-item-header div {
	margin: 16px 24px;
	
	&.flex-header {
		flex: 1;
	}	
}
