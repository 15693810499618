img.QRcode2FA {
	display: block;
	margin: 8px auto;
}

input.token2FA {
	display: block;
	margin: 0 auto;
	padding: 0 8px;
	border-radius: 4px;
}
