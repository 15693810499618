/* Tabel met invoervelden (editpagina) */

.fieldsetbox {
	vertical-align: top;
	margin-bottom: 8px;
	padding: 8px;
	border-radius: @border-radius-large;
	box-shadow: 0px 0px 8px rgba(13, 13, 13, 0.12),
		0px 0px 16px rgba(13, 13, 13, 0.08);
	background-color: @white;
	min-width: auto;
	max-width: -webkit-fill-available;
	min-height: 32px;

	> table {
		width: 100%;
		border-collapse: collapse;
		margin-right: 40px;
	}

	td.tblTag {
		vertical-align: middle;
		line-height: 20px;
		width: 220px;
		padding-right: 6px;
	}
		
	td.tblTag.alignTop{
		vertical-align: initial;
	}

	td.tblInput {
		vertical-align: middle;
		overflow-wrap: anywhere;
		p {
			margin-top: 0;
		}
	}

	td.tblTxt {
		font-size: 1em;
		height: 20px;
		vertical-align: top;
	}

	.fieldsetbox {
		border: none;
		background: none;
		box-shadow: none;
		padding-left: 0;
		padding-right: 0;
	}

	a.btn {
		display: inline-block;
		margin-bottom: 5px;
	}

	.checkboxContainer {
		margin-bottom: 20px;
		span {
			font-weight: bold;
			input {
				margin-right: 5px;
			}
		}
	}
}

.fieldsetcontent {
	vertical-align: top;
	width: auto;
	padding-right: 8px;
	display: inline-block;
}

.fieldsetcontent_medium_wide {
	.fieldsetcontent;
	width: 750px;
}

.fieldsetcontent_wide {
	.fieldsetcontent;
	width: 850px;

	div {
		width: 100%;
	}

	.fieldsetbox td.tblInput {
		width: 630px;
	}
}

div.modalLayLeft
	div.relative
	div.fieldsetcontainer
	div.fieldsetbox
	table.tblData
	tbody
	tr {
	td.tblTag {
		width: auto;
	}

	td.tblInput {
		width: auto;
	}
}

// Overwrite van de (te smalle) Cobra datumTijdField
.datumTijdField { 
	.unit_120 {
    	width: auto !important;
    	margin-right: 8px;
	}
}

.fieldset_with_no_bottom_space {
	div.fieldsetbox {
		padding-bottom: 0px !important;
		margin-bottom: 0px !important;
	}
}

.feature_panel .fieldsetbox {
	input.inpText {
		width: 500px;
	}
	textarea.inpText{
		width: 512px;
	}
}