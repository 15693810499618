.datablok {
	border: 1px solid @greyscale5;
	border-radius: 4px;
	background-color: @white;
	padding-left: 16px;
	padding-right: 16px;
	padding-bottom: 8px;
	margin-top: 8px;
	
	H1 {
		font-size: 14px;
		margin-top: 16px;
		margin-bottom: 16px;
		font-weight: @medium;
	}
	
	H2 {
		font-weight: @medium;
	}
	
	H3 {
		color: @font2;
		font-weight: 400;
	}
	
	HR {
		margin-left: -16px;
		margin-right: -16px;
		border-top: 1px solid @greyscale5;
	}
	
	.caption {
		margin-top: 16px;
		font-weight: @medium;
	}
	
	&:last-child {
		margin-bottom: 16px;
	}

	.toelichtingContainer {
		padding-top: 8px;
		padding-bottom: 8px;
	}
	
	.ck.ck-content {
		height: 200px;
	}
}

.datablok-met-header {
	background-color: @white;
	margin-top: 8px;
	margin-bottom: 16px;
	min-width: 616px;

	.datablok-header {
		padding-left: 16px;
		padding-right: 16px;
		padding-top: 1px;

		background-color: @greyscale7;
		color: @black;
		
		border: 1px solid @greyscale5;	    
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		
		&:has(a.closed) {
			border-bottom-left-radius: 4px;
			border-bottom-right-radius: 4px;
		}		
		
		&:has(a.open) {
			border-bottom-left-radius: 0px;
			border-bottom-right-radius: 0px;
		}
		
		a {
			span.accordeon-icons.icon-caret-up, span.accordeon-icons.icon-caret-down {
				float: right;
				font-size: 16px;
				margin-top: -4px;
			}
		}		
	}
	
	.datablok-content {
		padding: 16px;
		border: 1px solid @greyscale5;
		border-top: 0;
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
		
		.toelichtingContainer {
			padding-bottom: 16px;
		}
	}
	
	fieldset {
		display: grid;
		grid-template-columns: 4fr 7fr;
		grid-gap: 8px;
		padding: 0;
		margin: 0;

		label {
			display: flex;
			align-items: center;
		}
		
		span.value {
			display: flex;
			align-items: center;
			height: 32px;
			
			&.lookup {
				color: @font2;
			}
		}
		
		input {
			border: 1px solid @greyscale3;
			
			&.inpText.ui-autocomplete-input, &.hasDatepicker {
				width: -moz-available;
				width: -webkit-fill-available;
				margin-right: 8px;
			}
			
			&.inpText.quickSearch-nobutton {
				width: -moz-available;
				width: -webkit-fill-available;
				margin-right: 0px !important;
			}
		}
		
		textarea {
			width: -moz-available;
			width: -webkit-fill-available;
			resize: none;
		}
		
		select {
			width: 100%;
		}

		.datepicker-container {
			display: flex;
			
			.ui-datepicker-trigger {
				margin: 0;
			}
		}
		
		.searcheditor-container {
			display: flex;
			
			.icon-add {
				align-self: center;
				width: 32px !important;
				height: 28px !important;
				align-content: center;
				margin-left: 8px !important;
			}
		}
		
		.multiple-component-container {
			display: flex;
			align-items: center;
			
			&.einddatumjaar {
				display: grid;
				grid-gap: 8px;
				grid-template-columns: 2fr 15fr
			}

			input[type='checkbox'] {
				margin-right: 4px;
				margin-left: 4px;
			}

			.checkbox { 
				margin-left: 4px;

				> span {
					padding: 0;
				}
			}
			
			.adres {
				min-width: 66%;
				min-height: 80px;
				background: @greyscale7;
				border-radius: 4px;
				
				p {
					margin: 8px;
				}
			}
		}

		.icon-info-alt {
			padding-left: 4px;
		}
		
		.warning {
		    display: flex;
		    align-items: flex-start;
			background-color: @warning6;
			color: @font1;
		    padding: 12px;
			border: 1px solid @warning4;
			border-radius: 2px;
    		
    		.icon-exclamation-triangle {
    			padding-right: 16px;
				font-size: x-large;
				color: @warning4;
    		}
			
			a {
				color: @warning4;
			}
		}
	}	
}

.flex-2-column-container {
	display: flex;
	gap: 16px;
	
	.flex-column {
		flex: 0 0 616px;
	}
}
