.klaarzettenModal {
	p {
		margin: 0;
	}

	.dossierType {
		p {
			margin: 1em 0;
		}
	}

	.inpQuickSearch {
		justify-content: space-between;
		width: 265px;
	}
}
