// weergave cijfers in bolletjes

.grade--balls div {
	display: inline-block;
	margin: 0 auto;
}

.grade--balls figure {
	display: inline-block;
	border: 2px solid #232726;
	width: 2mm;
	height: 2mm;
	border-radius: 50%;
	margin: 0 1px;
}

.grade--balls figure.selected {
	background: #232726 !important;
}

.emoticon:before {
	font-family: emoticon;
	speak: none;
	font-size: 10px;
	font-style: normal;
	font-weight: normal;
	visibility: visible;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.emoticon.happy31:before {
	content: "\e04e";
}
.emoticon.emoticon79:before {
	content: "\e04f";
}
.emoticon.doubt2:before {
	content: "\e020";
}
.emoticon.sad33:before {
	content: "\e03a";
}
.grade--emoticon i {
	background: yellow !important;
	border-radius: 50%;
	width: 7mm;
	height: 7mm;
	display: block;
	border: #e0e000 2px solid;
	position: relative;
	margin: auto;
}
.grade--emoticon i:before {
	font-size: 24px;
	left: -2px;
	position: relative;
}

.rapport-subcolumnheader {
	border-top: @dungrijs;
	border-bottom: 1px solid #dedede;
}

/*deze style is voor de grijze tabelkopbalken */
.main_column {
	color: @font1;
	padding-top: 3px;
	padding-right: 8px;
	padding-bottom: 3px;
	padding-left: 5px;
	height: 22px;
	background-color: #f2f2f2;
	font-weight: bold;
}

/*deze style is voor de lichtgrijze subtabelbalkkop */
.main_subcolumn {
	background-color: #f2f2f2;
	color: @font1;
	padding-top: 3px;
	padding-right: 8px;
	padding-bottom: 3px;
	padding-left: 2px;
	height: 22px;
}

.main_subcolumn td {
	border-bottom: @dungrijs;
}

.main_label {
	color: @font1;
	padding-right: 5px;
	padding-left: 3px;
	padding-top: 2px;
	padding-bottom: 2px;
	height: 22px;
}

.main_label_no_width {
	color: @font1;
	padding-top: 2px;
	padding-right: 5px;
	padding-bottom: 2px;
	padding-left: 3px;
	height: 22px;
}

.main_label_number {
	color: @font1;
	padding-right: 5px;
	padding-left: 3px;
	padding-top: 2px;
	padding-bottom: 2px;
	width: 35px;
	height: 22px;
}

/* future obsoletes: */

.dottedlineseparator {
	border: 0;
	font-size: 1px;
	padding: 0;
	height: 0;
}

// rapport toevoegingen

.rapport-berekend {
	width: 10%;
	border-left: @dungrijs;

	span {
		background-color: @gray-30;
		border-radius: 2px;
		padding: 3px 5px;
		text-align: center;
		color: initial;
		margin-left: 19px;
		vertical-align: middle;
	}
}

.rapport-weergave {
	min-width: 80px;
	width: 5%;
}

.rapport-invoer {
	width: 2%;
	&:not(:last-child) {
		border-right: @dungrijs;
	}
}

.rapport-opmerking {
	border-left: @dungrijs;
	max-width: 500px;
	padding: 10px;
	&:not(:last-child) {
		border-right: @dungrijs;
	}
}

.rapportopmerking_input {
	min-height:120px;
	width: -webkit-fill-available;
	width: -moz-available;
}

.rubriek_header {
	width: 200px;
	text-align: left;
}

.periodenaam {
	text-align: center;
	vertical-align: baseline;
	line-height: 30px;
	a {
		margin: 0 4px;
	}
}

.rubrieknaam {
	border-top: 1px solid #cccccc;
	border-bottom: 1px solid #dedede !important;
}

