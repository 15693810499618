.checkbox {
	display: inline-flex;
	cursor: pointer;
	position: relative;
	line-height: normal;
}

.checkbox:has(> input:disabled) {
	cursor: default;
}

.checkbox > span {
	color: @black;
	padding-top: 6px;
	padding-right: 2px;
	padding-bottom: 2px;
	padding-left: 8px;
}

.checkbox > input {
	height: 21px;
	width: 21px;
	padding-left: 0;
	appearance: none;
	border: 3px solid @font4;
	border-radius: 4px;
	outline: none;
	background-color: @white;
	cursor: pointer;
}

.checkbox > input:disabled {
	cursor: default;
	border: 3px solid @greyscale5;
}

.checkbox > input:disabled + span {
	cursor: default;
	.tekst-grijs-disabled;
}

.checkbox.disabled_checkbox {
	cursor: default;
	> input {
		cursor: default;
		border: 3px solid @greyscale5;
		border-color: @greyscale5 !important;
	}

	> input:checked:not(:disabled) {
		border: 2px solid @primary3;
		background-color: @primary3;
		border-color: @primary3 !important;
	}
	
	> input:checked:disabled {
		border: 2px solid @greyscale5;
		background-color: @greyscale5;
		border-color: @greyscale5 !important;
	}
}

.checkbox > input:checked:not(:disabled) {
	border: 2px solid @primary1;
	background-color: @primary1;
}

.checkbox > input:checked:disabled {
	cursor: default;
	border: 2px solid @greyscale5;
	background-color: @greyscale5;
}

.checkbox > input:hover:not(:disabled) {
	border: 3px solid @primary1;
}

.checkbox > input:checked + span::before {
	font-family: @icomoon-font-family;
	content: @icon-checkmark;
	color: @white;
	position: absolute;
	font-size: 18px;
	top: 0.25em;
	left: 0.3em;
}

.checkbox.intermediate > input:checked + span::before {
	font-family: @icomoon-font-family;
	content: @icon-remove;
}

.content-container-order-select .checkbox {
	margin-top: 10px;
	margin-right: 10px;
}
