.caption.level2 {
	padding-left: 10px !important;
}

.caption.level3 {
	padding-left: 20px !important;
}

.caption.level4 {
	padding-left: 30px !important;
}

.caption.level5 {
	padding-left: 40px !important;
}

.caption.level6 {
	padding-left: 50px !important;
}

.caption.level7 {
	padding-left: 60px !important;
}

.caption.level8 {
	padding-left: 70px !important;
}

.caption.level9 {
	padding-left: 80px !important;
}

.label.level3 {
	padding-left: 10px !important;
}

.label.level4 {
	padding-left: 20px !important;
}

.label.level5 {
	padding-left: 30px !important;
}

.label.level6 {
	padding-left: 40px !important;
}

.label.level7 {
	padding-left: 50px !important;
}

.label.level8 {
	padding-left: 60px !important;
}

.label.level9 {
	padding-left: 70px !important;
}
