// FortyTwo-styling voor de tooltips in autofieldsets

// De standaard cluetip-styling wordt hiermee overschreven.
#cluetip {
	#cluetip-outer {
		border: none !important;
		box-shadow: 0px 4px 6px -4px rgba(13, 13, 13, 0.12), 0px 8px 8px -4px rgba(13, 13, 13, 0.08);
		background-color: @black !important;
	}
	div {
		border-radius: @border-radius-large + 2px;
		background-color: transparent !important;
	}
	#cluetip-title {
		border-top-right-radius: @border-radius-large + 2px;
		border-top-left-radius: @border-radius-large + 2px;
		border-bottom: 1px solid @white;
		background-color: @black;
		color: @white;
		padding-top: 16px;
		padding-bottom: 16px;
		margin-right: 16px;
		margin-left: 16px;
	}
	#cluetip-inner {
		padding: 16px;
		line-height: 24px;
		color: @white;
	}
}


// Cluetip error styling
.cluetip-jtip-error #cluetip-outer {
	background-color: @greyscale8 !important;
	background-image: url("@{images}/fortytwo-icons/icon-exclamation-triangle-white.png") !important;
	border: 1px solid @color-brand;
	color: @white;
	background-position-x: 8px;
	background-position-y: 20px;
	background-size: 16px;
}

.cluetip-jtip-error #cluetip-title {
	height: auto !important;
	color: @white !important;
	overflow: auto !important;
}

.cluetip-jtip-error #cluetip-inner {
	font-weight: normal;
}


// De standaard jQuery-tooltip wordt hiermee overschreven
div.ui-tooltip {
		border: none !important;
		box-shadow: 0px 4px 6px -4px rgba(13, 13, 13, 0.12), 0px 8px 8px -4px rgba(13, 13, 13, 0.08);
		background-color: @black !important;
		padding: 16px;
		font-size: @font-size;
		line-height: 24px;
		color: @white;
		h1 {
			color: @white !important;
    		font-weight: @medium;
    		border-bottom: 1px solid @white;
    		padding-bottom: 16px;
			margin-top: 0;
			margin-bottom: -8px;
		}
		p {
			margin: 0;
		}
}

// Een tooltip op basis van het attribuut [data-title]
[data-title]:hover::after {
	content: attr(data-title);
	position: absolute;
	min-width: 200px;
	max-width: 600px;
	border-radius: @border-radius-large + 2px;
	padding: 16px;
	color: @white;
	background-color: @black;
	z-index: 1;
}