/* Thirdmenu betreft het tab-menu dat onder andere bij plannen en het OPP wordt gebruikt. */
.thirdmenucontainer {
	height: 48px;
	background-color: @white;
	border: 1px solid @greyscale5;
	border-radius: 4px;
	margin-bottom: 16px;
}

.thirdmenu,
.thirdmenuitem,
.thirdmenucontainer > ul > li {
	font-size: @font-size + 2px;
	list-style-type: none;
	list-style: none !important;
	float: left;
	margin: 0;
	padding: 0 16px;

	span {
		color: @font1;
		&:hover {
			color: @secondary1;
		}
	}
}

.thirdmenu_disabled {
	color: @font3;
}

.thirdmenucontainer > ul {
	height: 48px;
	line-height: 44px;
	margin: 0;
	padding: 0;
}

.thirdmenu_selected,
.thirdmenuitem_selected,
.thirdmenucontainer > ul > li.thirdmenuitem_selected {
	border-bottom: 4px solid @primary1;
	span {
		color: @primary1;
	}
}

.thirdmenuitem_selected > a {
	color: @primary1;
}

.tab0.thirdmenuitem_selected {
	border-bottom-left-radius: 4px;
}
