// on-off Switch <a> element

a.on-off-switch {
	position: relative;
	display: inline-block;
	width: 50px;
	height: 28px;
	float: right;
	border-radius: 15px;
	transition: all 0.3s ease-in 0s;

	&:hover {
		cursor: pointer;
	}

	.label {
		text-align: center;
		display: block;
		margin: 2px;
		width: 50%;
		height: 28px;
		padding: 0;
		line-height: 28px;
		color: @white;
	}

	.switch {
		display: block;
		width: 24px;
		height: 24px;
		margin: 2px;
		position: absolute;
		font-size: @font-size;
		top: 0;
		bottom: 0;
		background-color: @white;
		border-radius: 15px;
		transition: all 0.3s ease-in 0s;
	}
}

// AAN-UIT switch

a.on-off-switch.on {
	background-color: @tertiary31;
	color: @white;
	border: 1px solid @color-tint-60;
	.label {
		float: left;
		color: @white;
		&:before {
			content: "AAN";
		}
	}
	.switch {
		left: 44%;
	}
}

a.on-off-switch.off {
	background-color: @gray-30;
	border: 1px solid @gray-40;
	color: @gray-70;
	.label {
		float: right;
		color: @gray-60;
		&:before {
			content: "UIT";
		}
	}
	.switch {
		left: 0%;
	}
}

// JA-NEE switch

a.on-off-switch.yes {
	background-color: @tertiary31;
	color: @white;
	border: 1px solid @color-tint-60;
	.label {
		float: left;
		color: @white;
		&:before {
			content: "JA";
		}
	}
	.switch {
		left: 63%;
	}
}

a.on-off-switch.no {
	background-color: @gray-30;
	border: 1px solid @gray-40;
	color: @gray-70;
	.label {
		float: right;
		color: @gray-60;
		&:before {
			content: "NEE";
		}
	}
	.switch {
		left: 0%;
	}
}

// Locked-Unlocked switch

a.on-off-switch.unlocked {
	background-color: @primary1;
	color: @white;
	width: 54px;
	.label {
		float: left;
		color: @white;
		font-size: @font-size;
		&:before {
			content: @icon-unlock;
		}
	}
	.switch {
		left: 44%;
	}
}

a.on-off-switch.locked {
	background-color: @greyscale6;
	color: @white;
	width: 54px;
	.label {
		float: right;
		font-size: @font-size;
		&:before {
			content: @icon-lock;
		}
	}
	.switch {
		left: 0%;
	}
}

// On-OFF switch checkbox

.onoff-checkbox {
	position: relative;
	width: 50px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;

	input[type="checkbox"] {
		display: none;
	}

	.onoffswitch-label {
		display: block;
		overflow: hidden;
		cursor: pointer;
		border-radius: 2px;

		.onoffswitch-inner {
			display: block;
			width: 200%;
			margin-left: -100%;
			transition: margin 0.3s ease-in 0s;
		}

		.onoffswitch-inner:before,
		.onoffswitch-inner:after {
			display: block;
			float: left;
			width: 50%;
			height: 22px;
			padding: 0;
			line-height: 22px;
			font-size: 11px;
			color: @white;
			box-sizing: border-box;
		}

		.onoffswitch-inner:before {
			content: "ja";
			padding-left: 5px;
			background-color: @tertiary31;
			color: @white;
		}

		.onoffswitch-inner:after {
			content: "nee";
			padding-right: 5px;
			background-color: #dde4e2;
			color: #999999;
			text-align: right;
		}

		.onoffswitch-switch {
			display: block;
			width: 10px;
			margin: 4px;
			background: #ffffff;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 28px;
			border: 2px solid #ffffff;
			border-radius: 2px;
			transition: all 0.3s ease-in 0s;
		}
	}
}

input[type="checkbox"]:checked + .onoffswitch-label .onoffswitch-inner {
	margin-left: 0;
}

input[type="checkbox"]:checked + .onoffswitch-label .onoffswitch-switch {
	right: 0px;
}

.onoff-checkbox.yes-no .onoffswitch-label {
	.onoffswitch-inner:before {
		content: "ja";
	}
	.onoffswitch-inner:after {
		content: "nee";
	}
}

.onoff-checkbox.on-off .onoffswitch-label {
	.onoffswitch-inner:before {
		content: "aan";
	}
	.onoffswitch-inner:after {
		content: "uit";
	}
}
