@media print {
	body {
		margin: 0;
		padding: 0;
		overflow: visible !important;
		background-color: transparent !important;
	}

	body * {
		visibility: hidden;
		overflow-y: visible !important;
		height: auto !important;
	}

	#main * {
		visibility: visible;
	}

	#main {
		background-color: #f9f9f9 !important;
		background-image: none !important;
		border-top: 0px !important;
	}

	.niveauA {
		border: 1px solid @grade-blue;
		font-weight: bold;
	}

	.niveauB {
		border: 1px solid @grade-green;
		font-weight: bold;
	}

	.niveauC {
		border: 1px solid @grade-yellow;
		font-weight: bold;
	}

	.niveauD {
		border: 1px solid @grade-orange;
		font-weight: bold;
	}

	.niveauE {
		border: 1px solid @grade-red;
		font-weight: bold;
	}

	.niveau_backgroundcolorless {
		border-collapse: separate;
		font-weight: bold;
		white-space: nowrap;
		outline-offset: 0px;
	}

	.sociogram--positief {
		background-color: @grade-green;
		border: 1px solid @grade-green !important;
		height: 30px !important;
		color: @grade-green;
	}

	.sociogram--negatief {
		background-color: @grade-red;
		border: 1px solid @grade-red !important;
		height: 30px !important;
		color: @grade-red;
	}

	a,
	a:active,
	a:visited,
	a:link,
	a:hover {
		color: #000 !important;
	}

	.tblButtons,
	.editButtonContainer,
	.editItem,
	.layTitlebarButtons,
	.ui-datepicker-trigger,
	input[value="Zoeken"],
	.laySearchbar fieldset a.btn-primary {
		display: none;
	}

	div#envelope-top {
		background-color: transparent !important;
		border: none !important;
		height: 40px;
	}

	.borderLeftRight {
		border: none !important;
	}

	.toelichting_print {
		font-style: italic;
		display: block;
	}

	.toelichting_span > img {
		display: none;
	}

	.main_title {
		color: #777;
	}

	.main_table .main_table {
		border: none;
	}

	.showInPrint {
		display: inherit !important;
	}

	.hideInPrint {
		display: none !important;
	}
}

// verberg in screen, toon alleen in print
.showInPrint {
	display: none;
}
