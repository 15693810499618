.vragenlijst_invullen_table {
	list-style-type: none;
	padding-left: 0px;
	> li {
		clear: both;
		list-style: none;
	}

	div.domein {
		margin-bottom: 10px;

		span {
			padding-left: 0;
		}
	}

	div.onderwerp {
		margin-bottom: 20px;

		span {
			padding-left: 0;
		}
	}
}
tbody.vraagEnAntwoord {
	width: 100vw;
}
.vraagEnAntwoord tr {
	grid-gap: 16px;
}
.antwoorden tr {
	display: flex;
	align-items: center;
}

table.vragenlijst_invullen_table > tbody.vraagEnAntwoord td.vraag {
	text-align: right;
}
table.vragenlijst_invullen_table > tbody.vraagEnAntwoord > tr > td {
	border-top: 1px dotted @gray-30;
}

.antwoorden > table td {
	width: fit-content;
}

.antwoorden > table input,
.antwoorden > table label {
	float: left;
	margin: 0px auto;
}

.antwoorden > table label {
	padding: 4px;
	min-width: 100px;
}

.antwoord_container {
	white-space: nowrap;
	overflow: hidden;
	width: inherit;
	line-height: 24px;
	input[type="radio"] {
		height: 32px;
	}
}

.collapsible_vragen .toelichting {
	font-style: italic;
	padding-left: 20px;
}

.requiredVragenlijstVraag {
	background-color: @color-brand-20;
	border: 1px solid @color-brand;
}

.numericalProgress {
	text-shadow: 0px 1px 1px @white;
	top: -4px;
}

.tab-panel > .vragenboom {
	margin-bottom: 50px;
}

.periode_progress {
	background-color: @white;
	width: 100px;
}

.periode_progress > .progress {
	color: @white;
	text-align: center;
	white-space: nowrap;
}

.progress > .green_progress {
	background-color: @tertiary31;
}

.ui-dropdownchecklist-text {
	line-height: normal;
}

.vragenlijst_invullen_table {
	counter-reset: vraag;
}

.vraagInleiding {
	max-width: 680px;
	margin: 0 0 20px 0;
	padding: 11px 0 20px;
	border-bottom: solid 1px @gray;
}

.vraagPanel {
	margin: 20px 0 10px;
	img {
		width: 150px;
		height: 150px;
		display: block;
		margin: 10px 0 20px 32px;
	}
}

.vraagTekst {
	margin-top: 10px;
}

.vraagTekst > div {
	border-top: 1px solid @color-brand-20;
	border-bottom: 1px solid @color-brand-20;
	padding: 2px;
	padding-left: 32px;
	font-weight: bold;
}

.vraagTekst > div:before {
	float: left;
	counter-increment: vraag;
	content: counter(vraag);
	margin-left: -34px;
	background-color: @color-brand-20;
	width: 16px;
	display: block;
	text-align: center;
	padding: 6px;
	font-weight: bold;
	margin-top: -6px;
}

.antwoordPanel {
	margin-top: 13px;
	margin-left: 32px;
	input[type="text"] {
		width: 400px;
	}
	input[type="number"] {
		width: 100px;
	}
}

.meerkeuzevraagAntwoorden,
.meerkeuzevraagAntwoorden > li {
	padding-left: 0px;
	margin-left: 0px;
	list-style: none !important;
}

.vragenlijsttype > th:nth-child(2),
td.plan,
th.plan,
thead.tussentijds th:nth-child(2) {
	padding-right: 20px;
}

// percentiel in observatielijsten

.percentiel_0 {
	background-color: @white;
	color: @black;
}

.percentiel_4 {
	color: @white;
	background-color: @grade-blue;
}

.percentiel_3 {
	color: @white;
	background-color: @grade-green;
}

.percentiel_2 {
	color: @white;
	background-color: @grade-orange;
}

.percentiel_1 {
	color: @white;
	background-color: @grade-red;
}
