/* niveau-kleuren t.b.v. toetsen en observatielijsten */
.niveau_generic {
	font-size: @font-size;
	color: @font1;
	border-radius: 2px;
	font-weight: bold;
	text-align: center;
	padding: 3px;
	text-transform: capitalize;
	min-width: 24px;
	display: inline-block;
}

.niveau_goedpercentage {
	width: 36px;
}

.niveau_empty {
	.niveau_generic;
	color: slategrey;
}

.niveau_disabled {
	.niveau_generic;
	background: @gray-30;
	color: @gray-80;
}

.niveauZien {
	.niveau_generic;
	color: @white;
	background-color: @indicatieuitspraak-pink;
}

.niveau_backgroundcolorless {
	.niveau_generic;
	color: @white;
}

.niveauA {
	.niveau_generic;
	color: @white;
	background-color: @grade-blue;
}

.niveauB {
	.niveau_generic;
	color: @white;
	background-color: @grade-green;
}

.niveauC {
	.niveau_generic;
	color: @white;
	background-color: @grade-yellow;
}

.niveauD {
	.niveau_generic;
	color: @white;
	background-color: @grade-orange;
}

.niveauE {
	.niveau_generic;
	color: @white;
	background-color: @grade-red;
}

.niveau-underline {
	text-decoration: underline;
}

.indicatieUitspraken {
	.niveau_generic;
	color: @white;
	background-color: @indicatieuitspraak-pink;
}

td.result-spacing,
th.result-spacing {
	width: 3px;
	border: none;
	background: @gray-20;
}

td.niveau_empty,
td.niveauA,
td.niveauB,
td.niveauC,
td.niveauD,
td.niveauE,
td.indicatieUitspraken,
td.niveau_disabled,
td.niveauZien,
td.niveau_backgroundcolorless {
	border: @dungrijs;
	border-radius: 0;
	min-width: 20px;
	display: table-cell;
}

.cijferkleur-onvoldoende {
	background-color: @grade-red;
}

.cijferkleur-zwak {
	background-color: @grade-yellow;
}

.cijferkleur-voldoende {
	background-color: @grade-green;
}

.cijferkleur-goed {
	background-color: @grade-blue;
}

.cijferkleur-onvoldoende-fill {
	fill: @grade-red;
}

.cijferkleur-zwak-fill {
	fill: @grade-yellow;
}

.cijferkleur-voldoende-fill {
	fill: @grade-green;
}

.cijferkleur-goed-fill {
	fill: @grade-blue;
}
