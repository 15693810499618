.fieldsetcontent.klaarzetten {
	width: 100%;
	
	#dossierContainer {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 16px;
		
		.dossier {
			display: flex;
			flex-direction: column;

			.buttons {
				display: flex;
			}

			input {
				margin-top: 15px;
			}
		}
		
		#voortgang-banner {
			background: none;
		}
	}	
}
