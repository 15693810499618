/*
 * Slide-in panel / side nav code is geleend van / geinspireerd op https://www.w3schools.com/howto/howto_js_sidenav.asp
 * Tooltip code is geleend van / geinspireerd op https://www.w3schools.com/css/css_tooltip.asp  
 */

.sidenav {
	width: 0;
	position: fixed;
	z-index: 999;
	top: 48px;
	background-color: white;
	overflow-x: hidden;
	transition: 0.5s;
	padding-top: 10px;

	&.sidenav-opened {
		width: 575px;

		> .content-container {
			min-width: 575px;
		}

		&.sidenav-kolommenkiezer-filter {
			width: 520px;

			> .content-container {
				min-width: 520px;
			}
		}
	}

	&.sidenav-right-sided {
		right: 0;
	}

	&.sidenav-left-sided {
		left: 0;
	}

	h1 {
		font-size: 24px;
	}

	.closebtn {
		cursor: pointer;
		position: absolute;
		top: 8px;
		right: 24px;
		font-size: 35px;
		margin-left: 50px;
		color: black;
		
		span.tooltiptext {
			top: 10px;
			right: 100%;
		}
	}
}

.panelOverlay {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	margin: 0;
	padding: 0;
	z-index: 1000;
	background: rgba(0, 0, 0, 0.2);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	
	&.darkerOverlay {
		background: rgba(0, 0, 0, 0.7);
	}
}

.tooltip .tooltiptext {
	visibility: hidden;
	font-family: "Ubuntu";
	width: 160px;
	background-color: black;
	color: #fff;
	border-radius: 6px;
	padding: 16px;
	font-size: @font-size;
	line-height: 20px;
	position: absolute;
	z-index: 1001;
	
	// normaal zit de tooltip rechts van het hover-icoon
	// met deze class kan je hem links van het icoon plaatsen
	&.tooltiptextleft {
		transform: translateX(-110%);
	}
}

.tooltip:hover .tooltiptext {
	visibility: visible;
}

.content-container {
	padding-left: 0;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: relative;
	height: ~"calc(100vh - 58px)";
}

.content-panel {
	overflow: auto;
}

.slideinpanel-content {
	padding: 0 16px;
	border-bottom: 1px solid @greyscale5;
}

.slideinpanel-bottombuttonbar {
	border-top: 1px solid @greyscale5;
	height: 48px;
	bottom: 0;
	position: relative;
	width: 100%;
	padding: 8px 11px 8px 16px;
	display: flex;
	align-items: center;
	box-sizing: border-box;

	&.flex-buttons {
		width: unset;
		display: flex;
		flex-direction: row;

		A {
			width: unset;
			height: 20px;
			flex: 1 1 0px;
		}
	}

	A {
		cursor: pointer;
		padding: 8px 12px;
		margin-right: 5px;
		.font-medium;
		text-align: center;
		white-space: nowrap;
		border-radius: @border-radius-large;
		border: none;

		&.primair {
			color: @white;
			background-color: @primary1;
			box-shadow: inset 0px -3px 0px rgba(13, 13, 13, 0.25);
			&:hover {
				color: @font5;
				background-color: @primary2;
				box-shadow: inset 0px -3px 0px rgba(13, 13, 13, 0.25);
			}
		}

		&.secundair {
			color: @primary1;
			background-color: @white;
		}

		&.destructive {
			color: @font3;
			background-color: @white;
			border: 2px solid @font3;
			box-shadow: none;
			padding: 6px 10px;
			&:hover {
				color: @font2;
				border: 3px solid @font2;
				padding: 5px 9px;
			}
		}

		&.affirmative {
			color: @white;
			background-color: @tertiary31;
			box-shadow: inset 0px -3px 0px rgba(13, 13, 13, 0.25);
			&:hover,
			&:active {
				color: @white;
				background-color: @tertiary32;
				box-shadow: inset 0px -3px 0px rgba(13, 13, 13, 0.25);
			}
		}
		
		&.icon-launch-after > SPAN {
			font-family: @font-family;
			padding-right: 26px;
		}
		&.icon-launch-after::after {
			display: block;
			float: right;
			margin-left: -67px;
			margin-right: 60px;
			margin-top: 2px;
		}
		&.icon-launch-after-bewerken > SPAN {
			font-family: @font-family;
			padding-right: 26px;
		}
		&.icon-launch-after-bewerken::after {
			content: @icon-launch;
			display: block;
			float: right;
			margin-left: -67px;
			margin-right: 32px;
			margin-top: 2px;
		}
	}
}

.padding-bottom {
	padding-bottom: 16px;
}

.opp_rectangle {
	.font-medium;
	height: 22px;
	width: 41px;
	color: @white;
	background-color: @secondary1;
	border-radius: 2px;
	display: inherit;
	justify-content:center;
	align-items: center;
	font-size: 12px;
	margin-top: 20px;
	inline-size: 41px;
}

.opp_handelingsplan {
	.padding_left_10;
	inline-size: 450px;
	overflow-wrap: break-word;
	line-height: 28px;
}
