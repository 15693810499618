.kolommen-wrapper {
	height: 0;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;

	> DIV {
		width: 50%;
		min-width: 600px;
	}

	.ck.ck-editor {
		width: auto;
	}
}

.linker-helft > DIV {
	margin-right: 4px;
}

.rechter-helft > DIV {
	margin-left: 4px;
}

.sideBySide {
	display: flex;

	> * {
		flex: 1;
	}

	> *:first-child {
		margin-right: 10px;
	}

	.datablok:last-child {
		margin-bottom: initial;
	}

	.ck.ck-editor {
		width: auto;
	}
}

a.toelichting-link {
	.font-medium;
	.hover-p2;

	SPAN.icon-comment-plus {
		font-size: 22px;
		vertical-align: middle;
		margin-right: 4px;
	}
}

a.open-close-link {
	position: relative;
	.hover-s1;

	span.accordeon-icons.icon-caret-up, span.accordeon-icons.icon-caret-down {
		float: right;
		font-size: 12px;
		margin-top: -22px;
	}
}

div.open-close-container div.ck-reset.ck-editor.ck-rounded-corners {
	padding: 8px 0px 14px 0px;
}

.verticalmenupanel .ontwikkelingsperspectiefPanel {
	width: 100%;
	padding-bottom: 8px;
	padding-left: 13px;
	padding-right: 13px;

	& > * {
		margin-left: 14px;
	}

	SELECT {
		max-width: 500px;

		OPTION:nth-child(1) {
			font-weight: normal;
		}
	}

	.resetLabelMargin {
		margin-left: -18px;
	}
}

.ontwikkelingsperspectiefPanel {
	SELECT.placeholder {
		color: @font3;
	}

	.placeholder OPTION:not(:nth-child(1)) {
		color: @font1;
	}
}

.verticalmenupanel .tooltiptext {
	width: 345px;
}

.ondersteuningsbehoefte-data-titel {
	color: @font3;
	padding-top: 8px;
}

.ondersteuningsbehoefte-data {
	> DIV {
		padding-bottom: 8px;
	}
}

.standaardteksten {
	padding-left: 16px;
	padding-right: 16px;

	.ck.ck-content {
		height: 200px;
	}

	&:last-child {
		padding-bottom: 10px;
	}
}

.standaardtekstenInfoPanel {
	margin-top: 10px;
	margin-bottom: 10px;
	background: @tertiary24;
	border-radius: 4px;
	display: block;
}

.verticalmenupanel:has(.standaardteksten) {
	min-width: 940px;
	width: 60%;
}

.niveau-weergave {
	&__wrapper {
		display: flex;
		gap: 32px;
	}

	&__inner-wrapper {
		display: flex;
		flex-direction: column;
		gap: 8px;
		padding: 16px 0;

		p {
			color: @font3;
			margin: 0;
		}

		div, a {
			font-weight: @medium;
		}
	}
}

.extra-tekstveld__panel {
	background-color: @greyscale8;
	border: 1px solid @greyscale5;
	border-radius: 4px;
	padding: 16px;
	margin-top: 12px;

	.extra-tekstveld__header {
		margin-top: 4px;
		font-weight: @medium;
	}

	input[type="text"] {
		width: 624px;
		box-sizing: border-box;
		font-size: 14px;
	}

	.ck.ck-editor {
		width: 624px;
	}

	.input-row {
		display: flex;
		flex-direction: row;
		gap: 16px;
		margin-bottom: 8px;
		box-sizing: border-box;

		label {
			margin-left: 0;
			width: 116px;
			font-size: 14px;
			margin-top: 6px;
		}
	}
}

.extra-tekstveld__button {
	margin-top: 12px;
}

.label--top {
	font-size: 14px;
	margin-top: 12px;
	margin-bottom: 8px;
	font-weight: 500;
	display: block;
	line-height: 20px;
}

.datablok .icon-info-alt.tooltip {
	vertical-align: text-top;
}

.uitstroom-editor__row {
	display: flex;
	flex-direction: row;
	gap: 1rem;
	width: 100%;
}

.uitstroom-editor__row .uitstroom-editor__col {
	width: 50%;
}

.uitstroom-editor__col .ck.ck-editor {
	width: 100%;
}

.extratekstveld_verwijdericon {
	width: 20px;
	padding: 3px;
	border-radius: 4px;
	height: 20px;
	font-size: 20px;
	cursor: pointer;

	border: 2px solid @font3;
	color: @font3;

	&:hover {
		border: 2px solid @font2;
		color: @font2;
	}
}

.extratekstveld_titel {
	margin-right: auto;
	margin-left: 0;
	font-weight: @medium;
}

.groeigrafiek__panel {
	padding: 0 16px 16px;
}

.groeigrafiek__input-container {
	width: 330px;
	margin-left: 0;
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.groeigrafiek__input-row {
	width: 100%;
	border-radius: @border-radius-large;
	border: 1px solid @greyscale5;
	padding: 8px 12px;
	box-sizing: border-box;
	font-size: 14px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.groeigrafiek__input-row .right {
	display: flex;
	flex-direction: row;
	gap: 4px;
	justify-content: flex-end;
	align-items: center;
	min-width: 150px;
}

.groeigrafiek__input-row input[type="number"] {
	width: 75px;
	box-sizing: border-box;
	font-size: inherit;
}

.groeigrafiek__input-row .text--grey {
	color: @font4;
	margin-left: 0;
	margin-right: auto;
}

.groeigrafiek__input-row .text--immutable {
	height: 38px;
	font-size: inherit;
	padding-left: 15px;
	width: 75px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
}

.groeigrafiek__input-row input[type="number"]:hover {
	border-color: @primary1;
	color: @secondary1;
}

.tooltip__icon .icon-info-alt.tooltip {
	margin-left: 2px;
	vertical-align: text-top;
}

.textAreaHeader {
	font-weight: @medium;
	margin-top: 16px;
	margin-bottom: 12px;
	font-size: 16px;
}

.groeigrafiek-canvas {
	&_container {
		max-width: 1400px;
	}

	&_header {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

.groeigrafiek-panel {
	&_container {
		display: flex;
		align-items: center;
		justify-content: center;
		height: calc(100vh - 380px);
	}

	&_info-icon {
		font-size: 48px;
		color: @greyscale3;
		background-image: url("@{images}/fortytwo-icons/icon-info-alt-duotone.svg") !important;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 100%;
		width: 56px;
		height: 56px;

	}

	&_info-link {
		color: @primary1;
		border-bottom: 1px solid @primary1;
	}

	&_empty-state {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;

		h3 {
			font-weight: @medium;
			margin-bottom: 0;
		}

		p {
			margin-top: 4px;
		}
	}
}
