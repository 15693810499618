.color-g1 {
	color: @greyscale1;
}

.filter-content-title {
	display: flex;
	align-items: center;
	line-height: 18px;

	h2 {
		font-size: @font-size + 2px;
		margin-left: 0;
	}

	span.tooltip {
		font-size: @font-size + 10px;
	}
}

.filter-content-subtitle {
	display: flex;
	justify-content: space-between;
	align-items: center;
	line-height: 8px;
	margin-right: 23px;

	h2 {
		.font-regular;
		font-size: @font-size + 2px;
		margin-left: 0;
	}
}

.slideinpanel-content .radio-container {
	font-size: @font-size + 2px;
}

.groepskaart-select2-container {

	.select2-search__field {
		padding-top: 8px !important;
		box-sizing: content-box !important;
		width: auto !important;
	}
	
	& .select2-selection.select2-selection--multiple {
		min-height: 48px;
		max-height: unset;
	}

	// voeg dropdown arrow toe
	& .select2-selection--multiple:before {
		font-family: @icomoon-font-family;
		font-size: @font-size + 2px;
		color: @greyscale1;
		content: @icon-angle-down;
		position: absolute;
		right: 8px;
		top: 35%;
	}

	& .select2-container--default .select2-selection--multiple {
		border: 1px solid @black;
		cursor: pointer;

		ul.select2-selection__rendered {
			padding-right: 25px;
			cursor: pointer;

			li.select2-selection__choice {
				.font-bold;
				font-size: @font-size;
				background-color: @primary3 !important;
				border-radius: 2px;
				color: @primary1;
				padding: 6px 0 0 10px;
				height: 32px;
				margin-top: 8px;

				& > .select2-selection__choice__remove {
					font-size: 20px !important;
					color: @primary1 !important;
					background-color: @primary3 !important;
					margin: 4px;
					float: right;
					right: 0;
					left: unset;

					:hover,
					:focus,
					:focus-within {
						color: @primary2 !important;
						background-color: @primary3 !important;
					}
				}

				span.select2-selection__choice__display {
					padding-right: 25px;
				}
			}

			li.select2-search.select2-search--inline {
				position: absolute;
				font-size: @font-size + 2px;

				& > INPUT {
					height: 32px;
					padding: 0;
					margin: 0;
					cursor: pointer;
				}
			}
		}
	}
}

// select2 voegt deze content (via js) toe in een nieuwe span onderaan de body
// je kunt hier een custom class aan geven via getSettings().setDropdownCssClass("class");

.groepskaart-select2-options {
	border: 1px solid @black;

	&.select2-dropdown--below {
		border-top: none;
		box-shadow: 0px 4px 6px -4px rgba(13, 13, 13, 0.12),
			0px 8px 8px -4px rgba(13, 13, 13, 0.08);
	}

	&.select2-dropdown--above {
		border-bottom: none;
		box-shadow: 0px -4px 6px -4px rgba(13, 13, 13, 0.12),
			0px -8px 8px -4px rgba(13, 13, 13, 0.08);
	}

	& .select2-results > .select2-results__options {
		max-height: unset;
	}

	& .select2-results__option[aria-selected="true"] {
		.font-bold;
		background-color: @primary4;
	}

	& .select2-results__option--highlighted[aria-selected] {
		background-color: @primary1;
	}
}

.tilegroup.isDisabled .tile-container {
	border-color: @greyscale4;
	cursor: default;
	
	span {
		color: @greyscale4;
	}
}
	
.tilegroup {
	display: flex;
	flex-wrap: wrap;
	left: 0px;

	.tile-container {
		cursor: pointer;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		margin: 8px 8px 0 0;
		min-width: 40px;
		height: 40px;
		left: 84px;
		top: 340px;
		border: 1px solid @greyscale1;
		box-sizing: border-box;
		border-radius: 2px;
	}

	.tile-container span {
		font-family: Ubuntu;
		font-size: @font-size;
		font-style: normal;
		font-weight: 700;
		line-height: 20px;
		letter-spacing: 0em;
		text-align: left;
		color: @greyscale1;
		padding: 8px;
	}

	/* Change styling while checked */
	input:checked + span {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 9px 10px;
		margin-left: -9px;
		margin-right: -2px;
		background: @primary4;
		border: 2px solid @primary1;
		box-sizing: border-box;
		border-radius: 2px;
		color: @primary1;
	}

	/* Hide default checkbox */
	input {
		-moz-appearance: none;
		-webkit-appearance: none;
		-o-appearance: none;
		padding: 0;
	}

	/* Show our checkbox */
	input:checked + span::before {
		display: inline-block;
		font-family: @icomoon-font-family;
		margin-right: 5px;
		font-size: @font-size + 2px;
		vertical-align: middle;
		color: @primary1;
		content: @icon-checkmark;
	}

	/* Hide any checkbox if unchecked */
	input:not(:checked) {
		display: none;
	}
}

.stickyHeader {
	height: ~"calc(100vh - 405px)";
	position: relative;
	overflow-y: auto;
	border: none !important;

	TABLE THEAD {
		TR {
			border-top: none;
		}

		position: sticky;
		top: 0px;
		z-index: 2;
		box-shadow: inset 0 1px 0 @greyscale4;
	}
}
