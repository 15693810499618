#dossier-aanvullen {
	h1, h2 {
		font-weight: 500;
		margin: 8px;
	}

	.aandachtspunten-header {
		font-weight: 500;
		margin: 8px;
		grid-gap: 4px;
		display: flex;
	}

	p {
		margin: 0 8px;
	}

	.container {
		display: flex;
		gap: 40px;
	}

	.contact {
		input[type="radio"] {
			accent-color: @primary1;

			+ label {
				display: inline-block;
				margin: 0 20px 3px 0;
			}
		}
	}

	.fieldsetcontent {
		flex: 1 0 0;

		.content-links {
			background: @white;
			padding: 8px 0;
			border: 1px solid @greyscale7;
		}

		.fieldsetbox {
			box-shadow: none;
			border: 1px solid @greyscale7;

			td.tblTag {
				width: 190px;
			}

			textarea {
				width: 255px;
			}
		}

		.fieldsetbox:only-child {
			box-shadow: none;
			background: none;
			border: none;
		}
	}

	@media (max-width: 1023px) {
		.container {
			flex-direction: column;
		}
	}
}
