// ============ Main menu balk

div#mainmenuBalk {
	overflow: hidden;
	width: 100%;
	background-color: @white;
	box-shadow: 0px 6px 8px -6px rgba(13, 13, 13, 0.12),
		0px 8px 16px -6px rgba(13, 13, 13, 0.08);

	div.top_button {
		float: left;
		height: 48px;
	}

	a.mainMenuItem {
		display: inline-block;
		text-align: center;
		text-decoration: none;
		color: @primary1;
		cursor: pointer;
		font-family: @font-family;
		font-weight: @medium;
		font-size: @font-size + 2px;
		line-height: 32px;
		height: 36px;
		margin: 8px;
		min-width: 16px;
	}

	i {
		display: inline-block;
		color: @primary1;
		font-size: @font-size + 6px;
	}

	a.mainMenuIcon {
		padding: 8px;
		font-size: 30px;
		display: inline-block;
		text-align: center;
	}

	div.is-selected {
		a.mainMenuItem {
			border-bottom: 4px solid @primary1;
		}
	}

	a:hover,
	a i:hover {
		color: @primary2;
	}

	a.beeldmerk {
		position: absolute;
		left: 0;
		display: inline-block;
		height: 32px;
		width: 32px;
		content: url("@{images}/ParnasSys-Lena.svg");
		padding: 8px 16px;
	}
}

.layTopbar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100vw;
}

.layTopbarLeft {
	margin-left: 64px;

	.right {
		position: absolute;
		right: 64px;
	}
}

.layTopbarRight {
	right: 8px;
	margin-left: 8px;

	a.helpLink {
		text-align: center;
		text-decoration: none;
		color: @primary1;
		cursor: pointer;
		font-family: @font-family;
		font-weight: @medium;
		font-size: @font-size + 2px;
		line-height: 32px;
		height: 32px;
		min-width: 16px;
		margin-left: 8px;
		margin-right: 8px;
	}
	
	#zendeskWidget {
		margin-right: .5rem;
	}
}

.ajaxIndicator {
	-webkit-animation: spin 0.8s infinite linear;
	-moz-animation: spin 0.8s infinite linear;
	-o-animation: spin 0.8s infinite linear;
	animation: spin 0.8s infinite linear;
	display: none;
	mask-image: url("@{assimg}/widgets/ajax-loader.svg");
	// -webkit-mask-image en size voor ondersteuning van masks door Edge
	-webkit-mask-image: url("@{assimg}/widgets/ajax-loader.svg");
	mask-size: contain;
	-webkit-mask-size: contain;
	background-color: @primary2;
	z-index: 2;
	margin: 8px;
	width: 16px;
	height: 16px;
}

.layTitlebarButtons {
	float: right;
	margin-top: 8px;
	margin-right: 10px;
}

.zoekresultaten_dropdown {
	.mixin-border-radius(3px);
	.mixin-box-shadow (2px 2px 2px @gray-40);
	background: no-repeat scroll 0 0 @gray-10;
	display: none;
	right: 0px;
	position: absolute;
	top: 75px;
	width: 200px;
	z-index: 99999;

	.tblDatagrid {
		margin-bottom: 0;
	}
}

.zoekresultaten_dropdown .tblSelected {
	background-color: @color-brand-30;
}

.dossiersluiten {
	background: url("@{images}/sluiten.gif") no-repeat scroll right 0
		transparent;
	line-height: 20px;
	height: 15px;
	width: 15px;
	background-size: contain;
	margin-left: -5px;
	margin-top: 5px;
	display: inline-block;
}

.dossiersluiten:hover {
	background: url("@{images}/sluiten_active.gif") no-repeat scroll right 0
		transparent;
	line-height: 20px;
	height: 15px;
	width: 15px;
	background-size: contain;
	margin-left: -5px;
	margin-top: 5px;
	display: inline-block;
}

// De blauwe menubalk wordt op Acceptatie getoond.
.blauw-menu {
	background-color: @tertiary21 !important;
	color: @white !important;
	border-bottom: 0 !important;

	div.is-selected {
		a.mainMenuItem {
			border-bottom: 4px solid @white !important;
		}
	}

	a,
	i {
		color: @white !important;
	}

	a:hover,
	a i:hover {
		color: @greyscale4;
	}

	a.beeldmerk {
		content: url("@{images}/beeldmerk-header.png") !important;
	}
}
