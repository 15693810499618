/* ////////////////////////////////////////////////////////////
secondary tab menu
/////////////////////////////////////////////////////////// */
.menucontainer {
	height: 36px;
	min-width: 920px;
	padding-top: 8px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.widget-menu {
	float: left;
	z-index: 3;

	li ul#rapportage-dropdown {
		margin-left: -70px;
		width: 190px;
	}

	li ul#legenda-dropdown {
		width: 155px;
		left: -48px;
		background-color: @gray-10;
	}

	li ul#legenda-dropdown-content,
	li ul#legenda-dropdown-kalender {
		right: 40px;
		width: 500px;
		max-height: 70vh;
		overflow: auto;
		border: @dungrijs;
		border-radius: 4px;
		background-color: @white;
		box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.12);
		padding: 16px;

		li {
			.legenda-titel {
				font-size: @font-size;
				font-weight: bold;
				margin-top: 8px;
			}

			.legenda-icoon {
				padding: 0;
				min-width: 16px;
				height: 16px;
				margin: 6px;
				float: left;
			}

			.legenda-icoon.legenda-icoon-ascii {
				margin-top: 0 !important;
			}

			.legenda-afkorting {
				font-weight: bold;
				color: @gray-60;
				font-size: @font-size;
			}

			.legenda-tekst {
				color: @font1;
				font-size: @font-size;
				line-height: 20px;
			}

			.legenda-icoon-tekst {
				.legenda-tekst;
				margin: 4px 0;
			}

			.icon-link-left,
			.icon-test {
				font-size: @font-size + 2px;
				float: left;
				padding: 8px;
			}

			&:first-child {
				margin: 0 !important;
			}
		}
	}

	li ul#legenda-dropdown-kalender {
		right: 19px;
		width: 200px;
	}

	#legenda-dropdown-content > li,
	#legenda-dropdown-kalender > li {
		border: 0;
	}

	li > ul,
	.widget-menu li > div.shred-menu {
		position: absolute;
	}

	li > div.shred-menu {
		width: 500px;
	}

	div.shred-menu ul {
		float: left;
	}

	ul {
		margin: 0;
		padding: 0;

		li {
			float: left;
			padding-left: 7px;
			list-style: none;

			&:hover {
				text-decoration: none;

				ul {
					display: block;
				}
			}
		}

		a.menuLink {
			.mixin-border-radius(4px 4px 0 0);
			z-index: 0;
			background-color: @white;
			border: 1px solid @greyscale4;
			border-bottom: 0;
			text-decoration: none;
			color: @font1;
			display: block;
			height: 30px;
			margin-right: 0px;
			padding: 2px;
			width: 100%;

			span,
			em > span {
				font-size: 1em;
				line-height: 31px;
				margin-left: 6px;
				font-style: normal;
				cursor: pointer;
			}

			&:hover {
				color: @secondary1;
				border-color: @secondary1;
			}
		}

		.wmActive > .menuLink {
			background-color: @greyscale8;
			color: @primary1;
			height: 31px;

			&:hover {
				color: @secondary1;
				border-color: @secondary1;
			}
		}
	}

	.widget-menu-dropdown > a > span {
		padding-right: 17px;
		background-image: url("@{images}/fortytwo-icons/icon-nav-down.svg");
		background-repeat: no-repeat;
		background-position: right;
		background-size: 10px;

		&:hover {
			background-image: url("@{images}/fortytwo-icons/icon-nav-down-secondary1.svg");
		}
	}

	.widget-menu-dropdown ul {
		.mixin-border-radius(0 0 4px 4px);
		display: none;
		width: 21em;
		z-index: 2;

		li {
			padding-left: 0;
			overflow: hidden;
			width: 100%;
			border: 1px solid @secondary1;
			border-top-width: 0px;
			border-bottom-width: 0px;

			&:last-child {
				.mixin-border-radius(0 0 4px 4px);
				border: 1px solid @secondary1;
				border-top-width: 0px;
			}

			&:last-child {
				.mixin-border-radius(0 0 4px 4px);
				border: 1px solid @secondary1;
				border-top-width: 0px;
			}

			.menuLink {
				font-size: 1em;
				line-height: 15px;
				text-align: left;
				width: 100%;
				border-radius: 0;
				border: 0;

				&:hover {
					background-color: @greyscale7;
					color: @secondary1;
				}
			}
		}
	}

	li.widget-menu-dropdown {
		&:hover {
			> a.menuLink {
				border: 1px solid @secondary1;
				border-bottom-color: @white;

				span {
					background-image: url("@{images}/fortytwo-icons/icon-nav-down-secondary1.svg");
				}
			}
		}

		&.wmActive {
			> a.menuLink {
				border-bottom-color: @greyscale8;

				&:hover {
					border: 1px solid @secondary1;
					border-bottom-color: @greyscale8;
				}

				span {
					background-image: url("@{images}/fortytwo-icons/icon-nav-down-primary1.svg");

					&:hover {
						background-image: url("@{images}/fortytwo-icons/icon-nav-down-secondary1.svg");
					}
				}
			}
		}
	}

	.dossierSluiten {
		height: 31px;
		display: flex;
		align-items: center;
		justify-content: space-around;
		padding-left: 4px;
		padding-right: 4px;

		&:hover {
			color: @secondary1;
		}
	}
}

.wmDisabled .menuLink {
	color: @gray-60;
	cursor: default;
}

.widget-menu-separator {
	border-bottom: 1px solid @gray-10;
	border-top: @dungrijs;
	height: 0;
	font-size: 0;
}

.wicket-envelope {
	z-index: 2;
	margin-top: -1px;
	height: 30px;
}

.wicket-envelope-right {
	float: right;
	height: 36px;
	margin-top: 1px;
	line-height: 30px;
	width: 100%;
	padding-right: 8px;

	li {
		list-style: none;
	}

	img {
		cursor: pointer;
	}
}

div#outerContainer,
div#mainmenuBalk,
div#contextBalk {
	width: 100%;
}

div#outerContainer {
	font-size: @font-size;
	background-color: @greyscale7;
}

div#contextBalk {
	height: 20px;
	background-color: @gray-40;
	border-top: @dunwit;
}

div#contentOuter {
	background-color: @greyscale7;

	.content {
		height: ~"calc(100vh - 48px)";
		display: flex;
		flex-direction: column;
	}
}

div#envelope {
	vertical-align: bottom;
}

div#messagebarContainer {
	background-color: @gray-40;
}

.wicket_orderUp a,
.wicket_orderDown a {
	padding-left: 16px;
	display: inline-block;
}

th.wicket_orderUp,
th > .wicket_orderUp {
	background-image: url("@{images}/fortytwo-icons/icon-caret-up.svg");
	background-repeat: no-repeat;
	background-position: center left !important;
	background-size: 12px !important;
	background-origin: content-box !important;
	padding-left: 4px;
}

th.wicket_orderDown,
th > .wicket_orderDown {
	background-image: url("@{images}/fortytwo-icons/icon-caret-down.svg");
	background-repeat: no-repeat;
	background-position: center left !important;
	background-size: 12px !important;
	background-origin: content-box !important;
	padding-left: 4px;
}

span.tblButtons {
	float: right;
}

.caption {
	width: 100%;
	margin-bottom: 8px;
	color: @font1;
	font-size: @font-size;
	font-weight: bold;
	margin: 8px 0px;
}

div.wicket-modal {
	div.w_blue {
		div.w_top,
		div.w_bottom,
		div.w_topLeft,
		div.w_topRight,
		div.w_bottomRight,
		div.w_bottomLeft,
		a.w_close {
			background-image: url("@{assimg}/modal/frame-grey-1-alpha.png") !important;
		}

		div.w_left,
		div.w_right {
			background-image: url("@{assimg}/modal/frame-grey-2-alpha.png") !important;
		}
	}

	div.w_caption {
		background-color: @gray-20 !important;
	}

	div.w_content_2 {
		background-color: @gray-20 !important;
	}

	div.w_content_3 {
		border: 1px solid @gray-70 !important;
	}
}
