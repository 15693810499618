.slideinpanel-samenvatting {
	padding-top: 8px;

	> DIV {
		display: flex;
		padding-bottom: 8px;

		> DIV {
			flex: 1;

			DIV:first-child {
				color: @font3;
				font-size: 12px;
				line-height: 18px;
			}

			DIV:nth-child(2) {
				.font-bold;
				font-size: @font-size;
				line-height: 20px;
				min-height: 20px;
			}
		}
	}
}

.slideinpanel-inhoud, .plannen-omschrijving {
	padding-top: 8px;
	padding-bottom: 8px;

	.htmlLabel {
		H1 {
			font-size: @font-size + 10px;
		}

		H2 {
			font-size: @font-size + 4px;
		}

		H3 {
			font-size: @font-size;
		}

		P, li {
			font-size: @font-size;
		}
	}

	DIV:first-child {
		color: @font3;
		font-size: @font-size;
		line-height: 20px;
	}

	.htmlLabel {
		font-size: @font-size;
		line-height: 24px;
	}
}

.slideinpanel-flex {
	display: flex;
	flex-direction: column;
	gap: 8px;

	.tblDatagrid tr, .tblDatagrid tr td div {
		color: @font1;
	}
}

.notitie-history {
	color: @font3;
	font-size: @font-size;
	line-height: 20px;
	padding-top: 8px;
	padding-bottom: 8px;
}

.plannen-omschrijving {
	padding-left: 16px;
}
