// page navigation element at the bottom of table datagrids in ParnasSys.

div.pagnav {
	display: flex;
	font-size: 16px;
	padding: 8px;

	div {
		border: 1px solid @white;
		border-radius: 2px;
		background-color: @white;
		height: 32px;
		width: 32px;
		font-weight: @medium;
		font-size: 14px;
		line-height: 20px;
		margin-left: 8px;
		display: flex;
		justify-content: space-around;
		align-items: center;

		span {
			line-height: 32px;
		}

		a {
			width: 32px;
			height: 32px;
			border-radius: 2px;
			color: @black;
			display: flex;
			justify-content: center;
			&:hover {
				background-color: @secondary4;
				color: @secondary1;
				border: 1px solid @secondary1;
			}
			div {
				background-color: @white;
				&:hover {
					border: 0 !important;
					padding-top: 3px;
					height: 29px;
				}
			}
		}
		a[disabled="disabled"] {
			&.cijfernav {
				background-color: @primary1;
				border: 1px solid @primary1;
				color: @white;
				&:hover {
					background-color: @primary1;
					border: 1px solid @primary1;
					color: @white;
				}
			}
			div {
				background-color: @white;
				color: @font4;
			}
			div:hover {
				background-color: @white;
				color: @font4;
				border: 1px solid @white;
			}
			&:hover {
				background-color: @white;
				color: @greyscale7;
				border: 1px solid @white;
			}
		}
	}

	div.pijlnav-icon {
		margin: 0;
		&:hover {
			background-color: @secondary4;
			color: @secondary1;
			border: 1px solid @secondary1;
		}
	}
}

// end of pagnav element
