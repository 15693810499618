#voortgang-banner {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: @greyscale7;
	padding: 0 16px;
	
	.info {
		h1 {
			margin: 0;
			font-size: 24px;
			line-height: 30px;
			font-weight: 400;
		}
		
		h2 {
			margin: 0 0 3px 0;
			font-size: 16px;
			line-height: 24px;
			font-weight: 400;
			color: @font2;
		}
	}
	
	.stappen {
		margin: 10px 0;
		display: flex;
		justify-content: flex-end;
		
		.stap {
			display: flex;
			align-items: center;
			
			> div {
				display: flex;
				width: 75px;
				justify-content: center;
				align-items: center;
				flex-direction: column;
			}
			
			&:after {
				content: '';
				width: 40px;
				height: 2px;
				background: @greyscale4;
				margin: 0 -9px 25px -9px;
			}
			
			&:last-of-type:after {
				display: none;
			}
			
			.cirkel {
				width: 38px;
				height: 38px;
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: @primary3;
				border-radius: 50%;
				margin-bottom: 5px;
				border: 2px solid @primary3;
				
				span {
					font-size: 22px;
					
					&.icon-checkmark {
						display: none;
					}
				}
			}
			
			&.afgerond {
				.cirkel {
					background-color: @primary3;
					border-color: @primary3; 
					
					span {
						color: @primary1;	
						font-size: 22px;
						
						&.icon-checkmark {
							display: inline;
						}
						
						&.cijfer {
							display: none;
						}
					}
				}
				
				.tekst {
					font-size: 14px;
				}
			}
			
			&.actief {
				.cirkel {
					background: @primary1;
					border-color: @primary1; 
					
					span {
						color: @white;
					}
				}
			}
			
			&.open {
				.cirkel {
					background: transparent;
					border-color: @greyscale4; 
					
					span {
						color: @greyscale4;
					}
				}
				
				.tekst {
					color: @greyscale4;
				}
			}
		}
	}
}