/* ubuntu-regular - latin */
@font-face {
	font-family: "Ubuntu";
	font-style: normal;
	font-weight: 400;
	src: url("../fonts/ubuntu/ubuntu-v15-latin-regular.woff2") format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url("../fonts/ubuntu/ubuntu-v15-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* ubuntu-italic - latin */
@font-face {
	font-family: "Ubuntu";
	font-style: italic;
	font-weight: 400;
	src: url("../fonts/ubuntu/ubuntu-v15-latin-italic.woff2") format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url("../fonts/ubuntu/ubuntu-v15-latin-italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* ubuntu-500 - latin */
@font-face {
	font-family: "Ubuntu";
	font-style: normal;
	font-weight: 500;
	src: url("../fonts/ubuntu/ubuntu-v15-latin-500.woff2") format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url("../fonts/ubuntu/ubuntu-v15-latin-500.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* ubuntu-500italic - latin */
@font-face {
	font-family: "Ubuntu";
	font-style: italic;
	font-weight: 500;
	src: url("../fonts/ubuntu/ubuntu-v15-latin-500italic.woff2") format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url("../fonts/ubuntu/ubuntu-v15-latin-500italic.woff")
			format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* ubuntu-700 - latin */
@font-face {
	font-family: "Ubuntu";
	font-style: normal;
	font-weight: 700;
	src: url("../fonts/ubuntu/ubuntu-v15-latin-700.woff2") format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url("../fonts/ubuntu/ubuntu-v15-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* ubuntu-700italic - latin */
@font-face {
	font-family: "Ubuntu";
	font-style: italic;
	font-weight: 700;
	src: url("../fonts/ubuntu/ubuntu-v15-latin-700italic.woff2") format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url("../fonts/ubuntu/ubuntu-v15-latin-700italic.woff")
			format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* FortyTwo Icomoon font */
@font-face {
	font-family: "Icomoon";
	src: url("../fonts/icons/icomoon.woff2") format("woff2"),
		url("../fonts/icons/icomoon.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: block;
}
/* weergave cijfers in emoticons */
@font-face {
	font-family: emoticon;
	src: url("../fonts/emoticon.eot") format("embedded-opentype"),
		url("../fonts/emoticon.woff") format("woff"),
		url("../fonts/emoticon.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}
