.fieldsetbox.zorgvraag-bijlages {
	padding: 3px 20px 10px 20px;
	
	h2,
	h3 {
		font-size: 16px;
		font-weight: 500;	
		color: #121212;
		margin-top: 16px;
		margin-bottom: 16px;
	}
	
	.geen-bijlages {
		margin-top: 6px;
		margin-bottom: 10px;
		max-width: 700px;
	}
	
	.bijlages {
		max-width: 760px;
	}
}

.kindkans-min-content {
	width: min-content;		
}

.fieldsetbox.kindkans-min-width {
	min-width: 500px;
}

.fieldsetcontent.gegevensblokken {
	padding-right: 0;
}
