/** 2
 * tblDatagrid is used for representing lists
 * tblData is used in forms, to align labels and inputs.
 * tblInputs puts the rows in a table closer together than only tblData would.
 * it is used in combination with tblData, never on its own.
 * tblGridview is used to show the individual photos of students in a group.
 **/

.tblInputs td {
	padding-top: 2px;
	padding-right: 6px;
	padding-bottom: 2px;
	padding-left: 2px;
}

// zorg ervoor dat de text niet over de bewerken knop gaat.
.geentextoverbewerkenknop .tblInputs td {
	padding-right: 35px;
}

.tblData {
	td.tblHeaderTag {
		color: @gray-80;
	}

	td.tblTagLang {
		width: 300px;
		padding-left: 20px;
		vertical-align: top;
		padding-top: 4px;
		height: 20px;
		color: @font1;
	}
}

.tblData thead th,
span.head {
	font-weight: @medium;
	border: 0;
	padding: 5px 5px 8px 5px;
	text-decoration: none;
	text-align: left;
	color: @font1 !important;
}

.tblDatagrid {
	width: 100%;
	background-color: @white;
	border: @dungrijs;
	border-collapse: collapse;
	margin-bottom: 8px;
	line-height: normal;

	thead {
		background-color: @greyscale7;
		font-weight: @medium;
		color: @font1;
		border-bottom: @dungrijs;
		td {
			background: @white;
			font-weight: normal;
		}
	}
	thead .inactive {
		color: @gray-60;
	}

	tr {
		border-bottom: @dungrijs;
		padding-left: 8px;
		padding-right: 8px;

		&.ongelezen {
			font-weight: @medium;
		}
	}

	tr:nth-child(even) {
		background-color: @greyscale8;
			td {
				div.periode_progress {
					background: @greyscale8;
				}
			}
	}

	th {
		text-align: left;
		font-weight: @medium;
		height: 40px;
		padding: 0 4px 0 8px;
		&.text-center {
			text-align: center;
		}
	}

	td {
		text-align: left;
		height: 32px;
		padding-left: 8px;
		padding-right: 4px;
		&.text-center {
			text-align: center;
		}
	}

	// groupheader in tbl
	tr.groupHeader_noBackground > td {
		font-style: italic;
		font-weight: bold;
		color: @font1;
		padding-left: 20px;
		cursor: default;
	}

	tr.groupHeader > td {
		font-style: italic;
		font-weight: bold;
		color: @font1;
		padding-left: 20px;
		cursor: default;
		background-color: @tertiary44;
	}

	tfoot {
		background-color: @greyscale7;
	}

	td .info_overlib {
		margin-top: -1px;
		margin-bottom: -5px;
	}

	// So that if tblData is nested inside tblDataGrid it doesn't render lines between items.
	.tblData {
		tr {
			border-bottom: none;
		}
	}
}

.tblData,
.tblDatagrid {
	tr.inactive td {
		color: @gray-60;
		font-style: italic;
	}

	tr.notClickable td {
		background-color: @gray-10;
		cursor: default;
	}
}

.tblDatagrid {
	tbody {
		tr:hover {
			background: @primary4;
		}
	}
}

.tblSelected.inactive td {
	color: @gray-60 !important;
}

// 2.1 tblClick Highlighting

.tblClick {
	tbody {
		tr:hover {
			cursor: pointer;
			background: @primary4;
			td {
				div.periode_progress {
					background: @primary4;
				}
			}
			td .invisible {
				visibility: visible;
			}
		}

		tr.groupHeader:hover {
			cursor: default;
			background-color: @tertiary44;
		}
	}
}

.defaultCursor {
	cursor: default !important;
	:hover {
		cursor: default !important;
	}
}

.table_container,
.table_container > div {
	width: 100%;
}

// 2.2 Expandable Datagrid --> expands regular tblDatagrid:*

.tblExpand {
	tr.accordion-primary {
		background-color: @gray-10;
		cursor: pointer;
	}

	td.expand-icon {
		text-align: middle;
		color: @primary1;
		overflow: hidden;
		text-align: center;
	}

	td.secondary-content {
		padding: 5px 5px 10px 5px;
	}
	
	td.secondary-content > div > hr {
		margin-left: initial;
		margin-right: initial;
	}
	
	td.secondary-content > div:last-of-type > hr {
		display: none;
	}
}
