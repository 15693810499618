// Gray searchbar used in parnasSys main application/

.laySearchbar {
	border-radius: @border-radius-small;
	background-color: @greyscale7;
	border: 1px solid @greyscale4;

	fieldset {
		display: flex;
		justify-content: space-between;
		margin: 0;

		.fieldsetContainer {
			display: flex;
			flex-wrap: wrap;

			> div {
				padding: 4px;
			}
		}

		.buttonContainer {
			display: flex;
			align-items: center;
		}

		.buttonContainer--align-end {
			align-self: end;
		}

		> div {
			input,
			select,
			img {
				float: left;
			}

			.searchBarLabel {
				margin-top: 2px;
				margin-bottom: 2px;
				color: @font1;
			}
		}

		input.btn {
			margin-top: 15px;
			margin-left: 5px;
		}
	}

	.inpText,
	.inpSelect {
		width: 160px;
	}

	.inpQuickSearch {
		width: auto;
		justify-content: space-between;
	}

	.ui-dropdownchecklist-selector {
		width: 160px;
		height: 15px;
		border: 1px solid @greyscale5;
	}

	input.hasDatepicker,
	input.ui-autocomplete-input {
		width: 139px;
	}

	.inpSelectNarrow {
		width: 100px !important;
	}

	.inpTextNarrow .inpText {
		width: 50px !important;
	}
}

.tab-panel .laySearchbar {
	margin-bottom: 5px;
	margin-right: 16px;
}

// PAR-12368 Bij grote schermen zoek-knop direct achter zoekvelden
@media screen and (min-width: 1366px) {
	.laySearchbar {
		fieldset {
			justify-content: flex-start;
		}

		.buttonContainer {
			margin-left: 8px;
		}
	}
}
