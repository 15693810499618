// ============ Footer (Bottomrow (footer) styling)

/* line_table is de stijl die aan de TABLE waarin de buttons of de error messages staan toegekend moet worden. Deze
* zorgt voor een horizontale lijn */
.line_table,
.modalLayFooter {
	background: @white;
	display: flex;
	justify-content: space-between;
	border-top: @dungrijs;
	position: fixed;
	bottom: 0;
	z-index: 1;
	width: 100%;
}

.bottomrow {
	border: none;
	padding-right: 16px;
	padding-left: 16px;
	padding-top: 4px;
	padding-bottom: 4px;
	color: @color-brand;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	.widget-button {
		display: flex;
		height: 32px;
	}
}

.schoollogo {
	line-height: 24px;
	display: flex;
	padding: 0;
}

.bottomrowlogo {
	min-width: min-content;
	display: flex;
}

.accountinfo_container {
	display: flex;
	flex-direction: column;
	padding-right: 16px;
	padding-top: 8px;
	text-align: right;
}

.accountinfo {
	color: @secondary1;
	font-size: @font-size;
	line-height: 15px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.account {
	color: @color-brand;
	white-space: nowrap;
}

/** In ParnasSys staan alle buttons links. Een aantal Cobra-buttons staan default rechts, dit fixen we hier.*/
.bottomrow.left > .right {
	float: left !important;
}

.appinfo {
	position: fixed;
	right: 0;
	bottom: 0;
	text-align: right;

	.buildinfo {
		color: @gray-60;
	}

	.sessieinfo {
		color: @black;
		font-weight: bold;
		margin-bottom: 2px;
	}
}

.modalLayLeft {
	bottom: 51px;
	left: 4px;
	overflow: auto;
	padding: 5px;
	position: absolute;
	right: 11px;
	top: 0px;
}

#wicketDebugLink {
	/** Bijna geen css-probleem wat niet met !important is op te lossen ;-) . */
	bottom: auto !important;
	right: 450px !important;
	top: 10px !important;
}

.modalLayFooter {
	bottom: 0px;
	height: 22px;
	left: 0px;
	overflow-x: auto !important;
	overflow-y: hidden !important;
	padding: 7px 10px;
	position: absolute;
	right: 0px;
}
