div.verticalmenu {
    padding-top: 16px;
    display: flex;
}

div.verticalmenupanel {
    flex: 1 1 auto;
    overflow: inherit;
    margin-bottom: 16px;
    margin-left: 16px;
    background: @white;
    border: 1px solid @greyscale5;
    border-radius: 5px;
    font-size: @font-size + 2px;
}

div.verticalmenupanel .titel-container {
    height: fit-content;
    border-bottom: 1px solid @greyscale5;
    padding-left: 16px;
    padding-bottom: 16px;
    padding-right: 16px;
    margin-bottom: 16px;

    div {
        padding-top: 16px;
        font-size: @font-size + 10px;
        line-height: 40px;
        .font-bold;
    }

    p, ul {
        margin: 0;
        color: @font3;
    }
}

div.verticalmenupanel .secondary-titel-container {
	height: fit-content;
	border-bottom: 1px solid @greyscale5;
	padding-left: 16px;

	div {
		padding-top: 24px;
		padding-bottom: 16px;
		font-size: @font-size + 2px;
		.font-bold;
	}

    .secondary-titel-container-subtitel {
        margin: 0;
        color: @font3;
    }
}

div.verticalmenupanel .content-container {
    border-top: 1px solid @greyscale5;
    padding: 16px;
}

div.verticalmenupanel .content-container-order-select {
    border-top: 1px solid @greyscale5;
    margin-top: -16px;
}

div.verticalmenutabs {
    flex: 0 0 272px;
    position: sticky;
    top: 0;
    border: 1px solid @greyscale5;
    border-radius: 5px;
    height: fit-content;
    height: -moz-fit-content;
}

div.verticalmenutabs ul {
    padding-left: 0;
    margin-block-start: 0;
    margin-block-end: 0;
}

div.verticalmenutabs ul li {
    display: flex;
    margin: 0;
    padding: 0;
    list-style-type: none;
    background: @white;
    color: @black;
    border-bottom: 1px solid @greyscale5;
    border-radius: 0px;
}

div.verticalmenutabs ul li:last-child {
    border-bottom: 0px;
}

div.verticalmenutabs ul li:first-child,
div.verticalmenutabs ul li:first-child a div.tab-item {
    border-radius: 4px 4px 0px 0px;
}

div.verticalmenutabs ul li:last-child,
div.verticalmenutabs ul li:last-child a div.tab-item {
    border-radius: 0px 0px 4px 4px;
}

div.verticalmenutabs ul li a div.tab-item {
    display: block;
    padding: 15px;
    width: 249px;
    border-left: 4px solid @white;
    padding-left: 4px;
}

div.verticalmenutabs ul li a div.tab-item span {
    line-height: 17px;
    background: @white;
    color: @black;
    font-size: @font-size + 2px;
    .font-regular;
    margin-left: 11px;
}

div.verticalmenutabs ul li.verticalmenuitem_selected a div.tab-item {
    border-left: 4px solid @color-brand;
}

div.verticalmenutabs ul li.verticalmenuitem_selected a div.tab-item span {
    background: @white;
    color: @black;
    .font-bold;
}

div.verticalmenutabs ul li a div.tab-item:hover span {
	background: @white;
	color: @secondary1;
}

div.verticalmenutabs ul li.verticalmenuitem_selected a div.tab-item:hover span {
	background: @white;
	color: @secondary1;
}

.leftmargin {
    margin-left: 16px;
}

.filterpanel {
    width: 100%;
    padding-bottom: 8px;
    padding-left: 13px;
    padding-right: 13px;
    border-bottom: 1px solid @greyscale5;

    font-size: @font-size + 2px;

    & > * {
        margin-left: 14px;
    }
}

.shrinktofit {
    width: 0.1%;
    white-space: nowrap;
}

.fillCell {
    width: ~"calc(100% - 25px)";
}

.rightMargin {
    margin-right: 4px;
}

.verticalmenupanel {
    INPUT {
        height: 36px;
        border: 1px solid @greyscale3;
        border-radius: 4px;
        font-size: @font-size + 2px;
        padding-left: 16px;
    }

    SELECT {
        min-width: 130px;
        max-width: 300px;
        height: 40px;
        border: 1px solid @greyscale3;
        border-radius: 4px;
        font-size: @font-size + 2px;
        padding-left: 16px;
        padding-right: 40px;

        appearance: none;
        background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik00LjMyMjQ3IDkuOTIzMzNMNS44Nzk0NyA4LjMyOTk5QzYuMzA5NDMgNy44OSA3LjAwNjUyIDcuODkgNy40MzY0OCA4LjMyOTk5TDE2IDE3LjA5MzNMMjQuNTYzNSA4LjMyOTk5QzI0Ljk5MzUgNy44OSAyNS42OTA2IDcuODkgMjYuMTIwNSA4LjMyOTk5TDI3LjY3NzUgOS45MjMzM0MyOC4xMDc1IDEwLjM2MzMgMjguMTA3NSAxMS4wNzY3IDI3LjY3NzUgMTEuNTE2N0wxNi43Nzg1IDIyLjY3QzE2LjM0ODUgMjMuMTEgMTUuNjUxNSAyMy4xMSAxNS4yMjE1IDIyLjY3TDQuMzIyNDcgMTEuNTE2N0MzLjg5MjUxIDExLjA3NjcgMy44OTI1MSAxMC4zNjMzIDQuMzIyNDcgOS45MjMzM1oiIGZpbGw9IiMxMjEyMTIiLz4KPC9zdmc+)
            no-repeat;
        background-position: right 12px top 10px;
        background-size: 20px;

        OPTION:nth-child(1) {
            font-weight: bold;
        }

        &:hover {
            border: 1px solid @black;
        }
    }

    LABEL {
        margin-left: 14px;
    }
}

.centerDiv {
    text-align: center;
    padding-top: 60px;
    padding-bottom: 60px;
}

.noSearchResult {
    .font-bold;
    color: @font3;
    font-size: 24px;
    line-height: 40px;
    padding-bottom: 16px;
}

.underlineLink {
    font-size: 16px;
    line-height: 24px;

    a {
        .font-bold;
        border-bottom: 2px solid @color-brand;
        padding-bottom: 2px;
        color: @color-brand;
    }
}

.info_message {
    height: 40px;
    display: flex;
    align-items: center;

    border-radius: 2px;
    margin-top: 8px;
    margin-right: 15px;
    background: @tertiary24;

    color: @tertiary21;
    font-size: @font-size;

    .suite-icon {
        margin-right: 15px;
        margin-left: 15px;
        font-size: @font-size + 4px;
    }

    div {
        margin-left: 15px;
    }
}
