@import "_groepskaart-kolommenkiezer.less";
@import "_groepskaart-filterpanel.less";
@import "_groepskaart-notitiepanel.less";

.leftText {
	text-align: left;
}

.geen-data {
	color: @font3;
}

.geen-kb-data {
	cursor: default;
	height: 33px;
	margin: -4px;
}

.header-tooltip {
	font-size: 24px;
	top: 4px;

	&.tooltip .tooltiptext {
		right: auto;
	}
}

// Om tabel minder groot te maken als er ook nog een pagina-melding aanwezig is. 
.groepskaart.reduce_groepskaart_height {
	height: ~"calc(100vh - 280px)";
}

.groepskaart {
	height: ~"calc(100vh - 240px)";
	position: relative;
	overflow-y: auto;
	text-align: center;

	TABLE.fortytwoTbl {
		TH:first-child {
			padding-right: 0px;
			border-right: none;
		}

		TD:first-child {
			padding-right: 0px;
		}
	}

	&.scrollableTbl {
		th,
		td {
			border-top: unset;
			white-space: nowrap;
		}

		.fixedColumn:first-child,
		.fixedColumnHeader:first-child,
		.fixedColumnFooter:first-child {
			left: -1px;
			width: 20px;
			min-width: unset;
		}

		.fixedColumn:nth-child(2),
		.fixedColumnHeader:nth-child(2),
		.fixedColumnFooter:nth-child(2) {
			left: 53px;
			width: 168px;
		}

		.fixedColumn:first-child {
			.topBoxShadow;
		}

		.fixedColumn:nth-child(2) {
			.rightTopBoxShadow;
		}

		.fixedColumnHeader.fixedFirstColumnHeader:first-child {
			.topRightBottomBoxShadow;
			width: 290px;
		}

		.fixedLastColumnHeader {
			.fixedColumnHeader;

			&:nth-child(2) {
				left: 53px;
				width: 168px;
				.rightBottomBoxShadow;
			}
		}

		.fixedColumnHeader:nth-child(2) {
			.rightBoxShadow;
		}

		.fixedColumnFooter:first-child {
			box-shadow: inset 0 -1px 0 @greyscale4,
			0 1px 0 @greyscale4;
		}

		.fixedColumnFooter:nth-child(2) {
			box-shadow: inset 0 -1px 0 @greyscale4,
			2px 0 0 0 @greyscale4,
			0 1px 0 @greyscale4;
		}
	}
}

div.filter-en-kolomkiezer {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	align-content: space-around;

	a {
		display: flex;
		align-items: center;
	}

	span {
		font-size: @font-size + 4px;
	}
}

.note-icon {
	content: url("@{images}/fortytwo-icons/icon-note.svg");
}

.note-new-icon {
	content: url("@{images}/fortytwo-icons/icon-note-new.svg");
}

.note-multiple-icon {
	content: url("@{images}/fortytwo-icons/icon-note-multiple.svg");
}

.note-multiple-new-icon {
	content: url("@{images}/fortytwo-icons/icon-note-multiple-new.svg");
}

.link-icon {
	content: url("@{images}/fortytwo-icons/icon-link.svg");
}

.link-new-icon {
	content: url("@{images}/fortytwo-icons/icon-link-new.svg");
}

.link-kindbegrip {
	content: url("@{images}/fortytwo-icons/icon-launch.svg");
	width: 24px;
	height: 24px;
	transform: translateY(4px);
}

.plan {
	.font-bold;
	color: @font2;
}

.plan-lopend {
	.plan;
	background-color: @positive3;
}

.plan-verlopen {
	.plan;
	background-color: @negative3;
}

.plan-gesloten {
	.plan;
	background-color: @greyscale5;
}

.score-kindbegrip {
	.plan;
	padding: 2px 4px;
}

.score-kindbegrip-legenda {
	padding: 0;
	min-width: 16px;
	height: 16px;
	float: left;
	margin-top: 8px;
	margin-right: 2px;
	margin-bottom: 6px;
	border-radius: 2px;
}

.score-kindbegrip-emo-laag {
	.plan;
	background-color: @kindbegrip-emo-laag;
}

.score-kindbegrip-emo-midden {
	.plan;
	background-color: @kindbegrip-emo-midden;
}

.score-kindbegrip-emo-hoog {
	.plan;
	background-color: @kindbegrip-emo-hoog;
}

.score-kindbegrip-soc-laag {
	.plan;
	background-color: @kindbegrip-soc-laag;
}

.score-kindbegrip-soc-midden {
	.plan;
	background-color: @kindbegrip-soc-midden;
}

.score-kindbegrip-soc-hoog {
	.plan;
	background-color: @kindbegrip-soc-hoog;
}

.score-kindbegrip-mor-laag {
	.plan;
	background-color: @kindbegrip-mor-laag;
}

.score-kindbegrip-mor-midden {
	.plan;
	background-color: @kindbegrip-mor-midden;
}

.score-kindbegrip-mor-hoog {
	.plan;
	background-color: @kindbegrip-mor-hoog;
}

.score-kindbegrip-av-laag {
	.plan;
	background-color: @kindbegrip-av-laag;
}

.score-kindbegrip-av-midden {
	.plan;
	background-color: @kindbegrip-av-midden;
}

.score-kindbegrip-av-hoog {
	.plan;
	background-color: @kindbegrip-av-hoog;
}

.score-kindbegrip-wb-laag {
	.plan;
	background-color: @kindbegrip-wb-laag;
}

.score-kindbegrip-wb-midden {
	.plan;
	background-color: @kindbegrip-wb-midden;
}

.score-kindbegrip-wb-hoog {
	.plan;
	background-color: @kindbegrip-wb-hoog;
}

.score-kindbegrip-ev-laag {
	.plan;
	background-color: @kindbegrip-ev-laag;
}

.score-kindbegrip-ev-midden {
	.plan;
	background-color: @kindbegrip-ev-midden;
}

.score-kindbegrip-ev-hoog {
	.plan;
	background-color: @kindbegrip-ev-hoog;
}


.groepskaart-ondersteuningsbehoefte {
	.font-bold;
	color: @font2;
	background-color: @greyscale5;
}

.notificatie-ongelezen {
	height: 8px;
	width: 8px;
	background-color: @negative1;
	border-radius: 50%;
}

#legenda-dropdown-content div.notificatie-ongelezen {
	margin-left: 17px;
	margin-top: 2px;
	position: absolute;
}

.groepskaart table td span.notificatie-ongelezen {
	.notificatie-ongelezen;
	padding: 0px;
	margin-left: -5px;
	margin-top: -4px;
	position: absolute;
}

.groepskaart .melding span {
	display: block;
	font-size: 12px;
	line-height: 18px;
	color: @font1;

	&.icon {
		font-size: 24px;
		.font-regular;
	}
}
