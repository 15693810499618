.ondersteuningsbehoefteinstellingen-panel {
	padding-right: 16px;
	padding-bottom: 16px;
	padding-left: 16px;

	display: flex;
	flex-direction: column;
	gap: 8px;

	.resetLabelMargin {
		margin-left: -19px;
	}

	.ck.ck-content {
		height: 200px;
	}

	select {
		min-width: 350px;
	}
}

.menuContainer.menuContainerOndersteuningsbehoefte {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: @white;
	border: 1px solid @greyscale5;
	border-radius: 4px 4px 0 0;

	.secondaryMenu {
		border: none;
	}

	.rightPanelOpenLink {
		.font-medium;
		display: flex;
		align-items: center;
		padding: 0 20px;
	}

	.icon-container {
		width: 25px;
		height: 25px;
		background-size: 20px;
	}
}

.menuMetOmgevingPanelLink {
	.tertiaryMenu {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.rightPanelOpenLink {
		.font-medium;
		display: flex;
		align-items: center;
		padding: 0 20px;
	}

	.icon-container {
		width: 25px;
		height: 25px;
		background-size: 20px;
	}
}

.omgeving-slideinpanel {
	DIV:first-child {
		padding-left: 16px;
	}

	DIV {
		padding-top: 8px;
		padding-bottom: 8px;
		padding-right: 8px;
		border-bottom: 1px solid @greyscale5;

		.htmlLabel {
			H1 {
				font-size: @font-size + 10px;
			}

			H2 {
				font-size: @font-size + 4px;
			}

			H3 {
				font-size: @font-size;
			}

			P, li {
				font-size: @font-size;
			}
		}
	}

	DIV:last-child {
		border-bottom: 0;
	}
}
