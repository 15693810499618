TABLE.fortytwoTbl {
	text-align: center;
	border-left: 1px solid @greyscale4;
	box-shadow: 1px 1px 1px @shadow;
	font-size: @font-size;
	white-space: nowrap;

	THEAD {
		position: sticky;
		top: 0px;
		z-index: 2;
		background-color: @greyscale7;
		box-shadow: inset 0 1px 0 @greyscale4;

		TR:last-child {
			box-shadow: 0px 8px 12px -10px rgba(0, 0, 0, 0.45);
		}

		TR:first-child TH {
			padding-left: 16px;
			padding-right: 16px;
			text-align: left;
			border-right: 1px solid @greyscale4;
		}
	}

	TBODY {
		background-color: @white;

		TR {
			box-shadow: inset 0 1px 0 @greyscale4;

			.categorie-lijn {
				border-right: 1px solid @greyscale4;
			}
		}

		TR:hover {
			background-color: @primary4;
		}

		TR:nth-child(even):not(:hover) {
			background-color: @greyscale8;
		}
	}

	TFOOT {
		position: sticky;
		bottom: 0px;
		z-index: 2;
		background-color: @greyscale7;
		box-shadow: inset 0 -1px 0 @greyscale4, 0 1px 0 @greyscale4;
		font-weight: bold;

		TR:first-child {
			box-shadow: inset 0 0 @greyscale4, 0 -2px 0 @greyscale4;
		}
	}

	TR {
		height: 32px;
	}

	TH:first-child {
		padding-left: 16px;
	}

	TD:first-child {
		padding-left: 16px;
	}

	TD:last-child {
		border-right: 1px solid @greyscale4;
	}

	TD {
		padding-left: 5px;
		padding-right: 5px;
		padding-top: 2px;
		padding-bottom: 2px;

		SPAN {
			font-size: @font-size;
			padding: 4px;
			line-height: 16px;
			vertical-align: bottom;

			&.note-icon, &.note-new-icon, &.note-multiple-icon, &.note-multiple-new-icon {
				padding: 0px;
				vertical-align: middle;
			}

			&.zien-resultaat {
				display: block;
				padding-right: 0;
				padding-left: 0;
				width: 36px;
				text-align: center;
			}
		}
	}

	TH {
		padding-left: 5px;
		padding-right: 5px;
		border-right: 1px solid @greyscale4;
		min-width: 40px;

		DIV {
			font-weight: normal;
			font-size: 12px;
			padding-top: 3px;
		}

		&.sortable-header {
			font-weight: bold;

			SPAN {
				vertical-align: bottom;
				color: @primary1;
				cursor: pointer;
				font-family: @font-family;
			}
		}
	}

	.underline_row TH {
		box-shadow: inset 0 -1px 0 @greyscale4;
	}

	.underline_th {
		box-shadow: inset 0 -1px 0 @greyscale4;
	}
}
