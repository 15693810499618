tr.clickableRow {
	cursor: pointer;

	.tabInfo {
		height: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;

		a {
			height: 100%;
			display: flex;
			align-items: center;
			flex: 1 0 0;
		}
	}
}

.menu-circle {
	border-radius: 50%;
	width: 8px;
	height: 8px;
	display: inline-block;
	margin: 2px 8px;
	background-color: @primary1;

	&.verplichteTab {
		background-color: @secondary1;
	}
}

.verplichteTab {
	.menu-circle {
		background-color: @secondary1;
	}
}


.ui-widget-content.overstapdossier-tooltip {
	background: @white !important;
	color: @black;

	H1 {
		color: @black !important;
		border-bottom: 1px solid #121212;
	}
}

.font-weight-500 {
	font-weight: 500;
}

.verzamelContainer {
	display: flex;
	gap: 40px;

	.fieldsetbox {
		box-shadow: none;
	}

	h1 {
		font-weight: 500;
	}

	h2 {
		font-weight: bold;
	}
}

.overstapdossierinhoudmenu {
	flex: 1 0 0;
	padding-top: 10px;
	max-width: 328px;
}

.categorienHeader {
	max-width: 6px;
}

.overstapdossiermenuitem_selected {
	background: @primary3 !important;
}

.overstapdossierinhoudmenupanel {
	flex: 3 0 0;
	padding: 12px;
	background: @white;
	border: 1px solid @greyscale7;
}

.tlvTitel {
	color: black;
	padding-bottom: 10px;
}

.tab-link {
	color: @black !important;
}

.overstapdossierInhoudTitel {
	width: 300px;
	font-family: 'Ubuntu';
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 40px;
	align-items: center;
	color: @black;
}

.overstapdossierlijn {
	border-bottom: 1px solid @greyscale4;
	padding-top: 10px;
}

.overstapdossierPill {
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 6px 16px;
	gap: 8px;
	left: calc(50% - 68px / 2 - 156.5px);
	top: 383px;
	border-radius: 100px;
	float: right;
	color: @white;

	&.toegevoegd {
		background: @primary1;
	}

	&.verplicht {
		background: @secondary2;
	}
}

.overstapdossierInfoPanel {
	margin-top: 10px;
	margin-bottom: 10px;
	background: @tertiary24;
	border: 1px solid @tertiary21;
	border-radius: 4px;
	display: block;
}

.geen-bijlages {
	display: flex;
	margin-bottom: 30px;
	padding: 30px;
	background: @greyscale7;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	font-weight: 500;

	.buttons {
		display: flex;
		margin-top: 20px;
	}
}

@media (max-width: 1023px) {
	.verzamelContainer {
		flex-direction: column;
	}
}
