.obh-categorie {
	display: block;
	margin-top: 15px;
	margin-bottom: 12px;
	font-size: @font-size - 2px;
	color: @font3;
}

.obh-titel {
	display: block;
	font-weight: bold;
	margin-right: 10px;
	margin-bottom: 5px;
}

.obh-tekst {
	display: block;
	margin-right: 10px;
	margin-bottom: 15px;
}