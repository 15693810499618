.collapseLabel {
	.font-bold;
}

.rightLabel {
	.font-bold;
	float: right;
	margin-right: 16px;
}

.panelheader {
	padding-top: 16px;
	padding-bottom: 8px;
}

.collapsedBar {
	margin-top: 6px;
	margin-right: 16px;
	margin-bottom: 16px;
	border: 1px solid @greyscale5;
	border-radius: 2px;
	
	&.nomargin {
		margin-top: 0;
		margin-right: 0;
		padding-top: 0;
		margin-bottom: 0;
	}

	.panelLabel {
		display: inline-block;
		padding-left: 6px;
	}

	.verticalAlign {
		height: 40px;
		display: flex;
		align-items: center;
	}

	.suite-icon {
		color: @primary1;
		font-size: @font-size + 4px;
		padding-left: 14px;
	}

	TABLE {
		width: 100%;
		font-size: @font-size;
		padding-left: 0px;
		border-collapse: collapse;
		table-layout: fixed;
		
		THEAD {
			text-align: left;
			background-color: @greyscale7;
		}

		TBODY {
			font-size: @font-size + 2px;

			TR:nth-child(even) {
				background-color: @greyscale8;
			}		

			TR:hover {
				background-color: @primary4;
			}
		
			TR:last-child {
				border-bottom: 1px solid @greyscale5;
			}		
		}

		TR {
			height: 40px;
			border-top: 1px solid @greyscale5;
		}

		TH , TD {
			padding-left: 8px;
		}

		TH:first-child , TD:first-child {
			padding-left: 36px;
		}

		TH:last-child {
			width: 60px;
		}

		TH.wicket_orderUp, TH.wicket_orderDown {
			SPAN {
				padding-left: 17px;
			}
		}
		
		TH.sortable-header SPAN {
			color: @primary1;
		}
	}
}

.text-10-grijs {
	color: @font3;
	font-size: 10px;
}

.text-12-font-bold {
	font-weight: bold;
	font-size: 12px;
}

.kindbegripscore {
	display: inline-block;
	width: 58px;
  	padding-top: 10px;
	height: 30px;
	border-radius: 4px;
	text-align: center;
}

.kindbegripscorepanel {
	height: 85px;
	
	TABLE {
		float: left;
		margin-right: 16px;
		border-collapse: collapse;
		line-height: 16px;
		
		TD {
			padding: 0;
		}
	}
}

TABLE.msvTable {	
	TD DIV.kindbegripscore {
		width: 118px;
		margin-right: 16px;
	}
}

.kindbegripbranding {
	color: @white;
	background-color: @secondary1;
	border-radius: 2px;
	font-size: 12px;
	padding-left: 8px;
	padding-right: 8px;
	padding-top: 2px;
	padding-bottom: 2px;
}

.kindbegripbrandingtitle{
	margin-left: 8px;
	margin-top: 2px;
	flex: 1;
}

.sidebysidekindbegrip {
	display: flex;	
}