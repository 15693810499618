.ontwikkelingsperspectiefAutofieldset {
	display: flex;
	
	DIV {
		padding-right: 16px;
		flex: 33%;
	}
	
	DIV>DIV:first-child {
		color: @font3;
		padding-top: 8px;
		padding-bottom: 8px;
	}
	
	DIV>DIV:last-child {
		font-weight: @medium;
		padding-bottom: 8px;
	}
}

.oppModalDialog_content {
	display: block;
	max-width: 450px;
}

.toelichtingSidePanelLink {
	display: block;
	position: absolute;
	top: 87px;
	margin-left: calc(66.6666% - 22px);
	font-weight: @medium;
}
